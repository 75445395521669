import { Font, StyleSheet } from '@react-pdf/renderer';

// Register fonts
Font.register({
    family: 'Noto Sans JP Bold',
    src: '/fonts/Noto_Sans_JP,Sacramento/Noto_Sans_JP/static/NotoSansJP-Bold.ttf',
});

Font.register({
    family: 'Noto Sans JP',
    src: '/fonts/Noto_Sans_JP,Sacramento/Noto_Sans_JP/static/NotoSansJP-Medium.ttf',
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        fontFamily: 'Noto Sans JP',
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 5,
        marginBottom: 10,
    },
    header: {
        fontSize: 16,
        textAlign: 'center',
        flex: 1,
        fontWeight: 'bold',
        fontFamily: 'Noto Sans JP Bold',
    },
    date: {
        fontSize: 12,
        textAlign: 'right',
        color: "#555",
        fontWeight: 'bold',
        paddingRight: 10,
        fontFamily: 'Noto Sans JP Bold',
    },
    underline: {
        marginTop: 5,
        borderBottomWidth: 1,
        borderBottomColor: "#000",
    },
    content: {
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        padding: 10,
        fontFamily: 'Noto Sans JP',
    },
    // table
    table: {
        marginTop: 40,
        fontSize: 12,
        border: '0.5px solid rgba(0, 0, 0, 0.43)',
        maxWidth: '96%',
        marginLeft: 12,
        fontFamily: 'Noto Sans JP',
    },
    tableRow: {
        margin: 0,
        padding: 0,
        flexDirection: 'row',
        minHeight: 30,
        alignItems: 'center',
        border: '0.5px solid rgb(0, 0, 0)'
    },
    tableHeader: {
        width: '20%',
        paddingLeft: '10px', 
    },
    tableHeaderLabel: {
        width: '40%',
        paddingLeft: '10px', 
        paddingTop: '5px',
    },
    tableContent: {
        width: '80%',
    },
    tableHeaderContent: {
        width: '60%',
        borderLeft: '0.5px solid rgb(0, 0, 0)',
        paddingLeft: '10px',
        paddingTop: '5px',
        minHeight: 30
    },
    contentLabel: {
        width: '25%',
        borderRight: '0.5px solid rgb(0, 0, 0)',
        height: '100%',
        paddingLeft: '10px',
        paddingTop: '5px',
    },
    contentValue: {
        width: '70%',
        height: '100%',
        paddingLeft: '10px',
        paddingTop: '5px',
    },
    // section 1
    section1: {
        display: 'flex',
        flexDirection: 'column',
        width: '96%',
        gap: '10px',
        marginLeft: 12,
        fontFamily: 'Noto Sans JP',
    },
    section1Row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px'
    },
    section1Title: {
        fontSize: 12,
        width: '20%',
        backgroundColor: '#000',
        color: '#fff',
        padding: 6,
        minHeight: 30
    },
    section1Content: {
        fontSize: 12,
        width: '80%',
        backgroundColor: '#f4f4f4',
        padding: 6,
        minHeight: 30
    },
    imageContainer: {
        width: '100%',
        marginTop: 30,
        display: "flex",
        flexDirection: "column",
        gap: 20
    },
    mapImage: {
        width: '70%', 
        marginBottom: 10,
    },
    legend: {
        height: 300,
        paddingLeft: 20
    },
    imagePair: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: '100%',
    },
    legendContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        padding: 10,
        backgroundColor: 'white',
        width: 'auto',
        alignItems: 'flex-start',
    },
    legendItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    legendBlock: {
        width: 15,
        height: 15,
        marginRight: 8,
        border: '1px solid #000',
    },
    legendText: {
        fontSize: 12,
        color: 'black',
    },
    chartContainer: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    
    chartImage: {
        width: '80%',
        height: 'auto', 
        objectFit: 'contain',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});


export default styles;
