// src/redux/slices/dataChangeSlice.js
import { createSlice } from '@reduxjs/toolkit';

const dataChangeSlice = createSlice({
    name: 'dataChange',
    initialState: {
        dataChanged: false
    },
    reducers: {
        setDataChanged: (state, action) => {
            state.dataChanged = action.payload;
        },
    },
});

export const { setDataChanged } = dataChangeSlice.actions;
export default dataChangeSlice.reducer;
