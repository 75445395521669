// src/redux/slices/polydataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const polydataSlice = createSlice({
    name: 'polydata',
    initialState: {
        aoiPolyData: null,
        plan1PolyData: null,
        plan2PolyData: null,
        plan3PolyData: null,
        aoisum: null,
        plan1sum: null,
        plan2sum: null,
        plan3sum: null,
        geotiffData: null,
    },
    reducers: {
        setAOIPolyDataInStore: (state, action) => {
            state.aoiPolyData = action.payload;
        },
        setPlanPolyDataInStore: (state, action) => {
            state[action.payload.plan + 'PolyData'] = action.payload.data;
        },
        setSumInStore: (state, action) => {
            state[action.payload.type + 'sum'] = action.payload.data;
        },
        setGeotiffDataInStore: (state, action) => {
            state.geotiffData = action.payload;
        },
    },
});

export const {
    setAOIPolyDataInStore,
    setPlanPolyDataInStore,
    setSumInStore,
    setGeotiffDataInStore,
} = polydataSlice.actions;
export default polydataSlice.reducer;
