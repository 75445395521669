import React, { useState, useEffect } from 'react';
import {
  Typography, TableContainer, Table, TableHead, TableRow, TableCell,
  TableBody, IconButton, Switch, Paper, Box, InputBase, Dialog, DialogActions,
  DialogContent, DialogTitle, Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCognitoUsers, updateCognitoUser, deleteCognitoUser } from '../../utils/carto';

const UsersTab = ({ langData }) => {
  const [users, setUsers] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isEnabledState, setIsEnabledState] = useState({});

  useEffect(() => {
    fetchCognitoUsers();
  }, []);

  const fetchCognitoUsers = async () => {
    try {
        const data = await getCognitoUsers();
        setUsers(data.Users);
        // Set initial isEnabled state for all users
        const initialIsEnabledState = {};
        data.Users.forEach(user => {
            const isEnabledAttr = user.Attributes.find(attr => attr.Name === 'custom:isEnabled');
            initialIsEnabledState[user.Username] = isEnabledAttr ? isEnabledAttr.Value === 'true' : false;
        });
        setIsEnabledState(initialIsEnabledState);
    } catch (error) {
        console.error("Error fetching Cognito users:", error);
    }
};

  const handleAdminToggle = (username) => {
    setIsEnabledState(prevState => {
        const newState = {
            ...prevState,
            [username]: !prevState[username],
        };
        updateCognitoUserAttribute(username, newState[username]);
        return newState;
    });
};

const updateCognitoUserAttribute = async (username, isAdminValue) => {
  try {      
    await updateCognitoUser(username, isAdminValue.toString());

    fetchCognitoUsers();
  } catch (error) {
    console.error("Error updating isAdmin attribute:", error);
  }
};

  const handleDeleteUser = async () => {
    try {
      await deleteCognitoUser(currentUser);
      fetchCognitoUsers();  // Re-fetch users to update the list
      setConfirmOpen(false);  // Close the dialog after operation
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const renderUserGridItems = () => {
    const filteredItems = users.filter((user) => {
      const usernameMatch = user.Attributes.find(
        (attr) => attr.Name === 'preferred_username'
      )?.Value.toLowerCase().includes(userSearchTerm.toLowerCase());
      const emailMatch = user.Attributes.find(
        (attr) => attr.Name === 'email'
      )?.Value.toLowerCase().includes(userSearchTerm.toLowerCase());
      return usernameMatch || emailMatch;
    });

    return (
      <TableContainer component={Paper} style={{ marginBottom: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{langData.username}</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{langData.email}</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{langData.iSEnabled}</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{langData.actions}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((user, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
                <TableCell>{user.Attributes.find((attr) => attr.Name === 'preferred_username')?.Value}</TableCell>
                <TableCell>{user.Attributes.find((attr) => attr.Name === 'email')?.Value}</TableCell>
                <TableCell>
                  <Switch
                    checked={user.Attributes.find((attr) => attr.Name === 'custom:isEnabled')?.Value === 'true'}
                    onChange={() => handleAdminToggle(user.Username, user.Attributes.find((attr) => attr.Name === 'custom:isEnabled')?.Value === 'true')}
                  />
                </TableCell>
                <TableCell>
                  <IconButton style={{ color: 'red' }} onClick={() => {
                    setConfirmOpen(true);
                    setCurrentUser(user.Username);
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div style={{ padding: 16, minHeight: 300, flex: 1, display: "flex", flexDirection: "column" }}>
      <Box marginBottom={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" gutterBottom>{langData.userHeading}</Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputBase
            placeholder={langData.searchPlaceholder}
            value={userSearchTerm}
            onChange={(e) => setUserSearchTerm(e.target.value)}
            endAdornment={
              <IconButton color="primary" onClick={() => setUserSearchTerm("")}>
                <SearchIcon />
              </IconButton>
            }
          />
        </div>
      </Box>
      {renderUserGridItems()}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>{langData.confirmDelete}</DialogTitle>
        <DialogContent>{langData.userDeletionWarning}</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>{langData.cancel}</Button>
          <Button onClick={handleDeleteUser} color="primary">{langData.delete}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersTab;
