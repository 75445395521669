import axiosAPI from "./axiosInstance";

async function deleteCognitoUser(username) {
  try {
    await axiosAPI.delete(
      `${process.env.REACT_APP_UE_API}/cognitouser/${username}`
    );
    return;
  } catch (error) {
    console.log("Error Deleting User:", error);
  }
}

async function getCognitoUser(username) {
  try {
    const { data: rows } = await axiosAPI.get(
      `${process.env.REACT_APP_UE_API}/cognitouser/${username}`
    );
    return rows;
  } catch (error) {
    console.log("Error Fetching User:", error);
  }
}

async function getCognitoUsers() {
  try {
    const { data: rows } = await axiosAPI.get(
      `${process.env.REACT_APP_UE_API}/cognitousers`
    );
    return rows;
  } catch (error) {
    console.log("Error Fetching Users:", error);
  }
}

async function updateCognitoUser(username, isEnabled) {
  try {
    return await axiosAPI.put(
      `${process.env.REACT_APP_UE_API}/cognitouser/${username}/${isEnabled}`
    );
  } catch (error) {
    console.log("Error Fetching Users:", error);
  }
}

async function fetchProjects() {
  try {
    const { data: rows } = await axiosAPI.get("/projects"); // Using .get() of axiosAPI
    return rows;
  } catch (error) {
    console.log("Error fetching data from API:", error);
  }
}

async function fetchMapData(table_name) {
  try {
    const data = await axiosAPI.get(`/source/${table_name}`);
    // console.log('row', data)
    return data;
  } catch (error) {
    console.log("Error fetching data from Carto:", error);
    throw error;
  }
}

async function fetchTileLayer(table_name) {
  try {
    const data = await axiosAPI.get(`/tilelayer/${table_name}`);

    return data;
  } catch (error) {
    console.log("Error fetching data from Carto:", error);
    throw error;
  }
}

const deleteProject = async (cartodb_id) => {
  try {
    const response = await axiosAPI.delete(`/projects/${cartodb_id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteSource = async (cartodb_id, tableName) => {
  try {
    const response = await axiosAPI.delete(
      `/sourceslist/${cartodb_id}/${tableName}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
const getProject = async (cartodb_id) => {
  try {
    const response = await axiosAPI.get(`/projects/${cartodb_id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getResult = async (cartodb_id, model_id) => {
  try {
    const response = await axiosAPI.get(`/result/${cartodb_id}/${model_id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getBufferCircle = async (projectId, mapType) => {
  try {
    const response = await axiosAPI.get(
      `/project/buffercircle/${projectId}/${mapType}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getAoi250Data = async (projectId) => {
  try {
    const response = await axiosAPI.get(`/aoi250/1/${projectId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const checkResult = async (cartodb_id) => {
  try {
    const response = await axiosAPI.get(`/checkresult/${cartodb_id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const updateProject = async (cartodb_id, projectData) => {
  try {
    const response = await axiosAPI.put(`/projects/${cartodb_id}`, projectData);
    return response;
  } catch (error) {
    throw error;
  }
};

const batchModeling = async (requestData) => {
  try {
    const response = await axiosAPI.post("/batch", requestData);
    return response;
  } catch (error) {
    throw error;
  }
};

const updatePlan = async (layerData, planData) => {
  try {
    const { plan_id, project_id, id: shapeId } = layerData;
    const response = await axiosAPI.put(
      `/projects/${project_id}/${plan_id}/shapes/${shapeId}`,
      planData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const deletePlanPolygonData = async (layer) => {
  try {
    const { plan_id, project_id, id: shapeId } = layer;
    const response = await axiosAPI.delete(
      `/projects/${project_id}/${plan_id}/shapes/${shapeId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getPlanPolygonData = async (id, plan_id) => {
  try {
    const response = await axiosAPI.get(`/projects/${id}/${plan_id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getPlanWiseGeoTiffData = async (projectId, planId) => {
  try {
    const response = await axiosAPI.get(
      `/projects/${projectId}/${planId}/tiffimages`
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deletePlanWiseGeoTiffData = async (projectId, planId) => {
  try {
    const response = await axiosAPI.delete(
      `/projects/${projectId}/${planId}/tiffimages`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const createProject = async (projectData) => {
  try {
    const response = await axiosAPI.post("/projects", projectData);
    return response;
  } catch (error) {
    throw error;
  }
};

const createBasemap = async (basemapData) => {
  try {
    const response = await axiosAPI.post(`/basemaps`, basemapData);
    return response;
  } catch (error) {
    throw error;
  }
};

const updateBasemap = async (id, basemapData) => {
  try {
    const response = await axiosAPI.put(`/basemaps/${id}`, basemapData);
    return response;
  } catch (error) {
    throw error;
  }
};

const getBasemapsData = async () => {
  try {
    const response = await axiosAPI.get(`/basemaps/`);
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteBasemap = async (id) => {
  try {
    const response = await axiosAPI.delete(`/basemaps/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};


const uploadGeotiff = async (data) => {
  try {
    const response = await axiosAPI.post("/project/geotiffupload", data);
    return response;
  } catch (error) {
    throw error;
  }
};

const fileSourceUpload = async (data) => {
  try {
    const response = await axiosAPI.post("/sourceupload", data);
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchCategories = async () => {
  try {
    const response = await axiosAPI.get("/source");
    return response.data.map((item) => item.table);
  } catch (error) {
    throw error;
  }
};

const fetchModels = async () => {
  try {
    const response = await axiosAPI.get("/models");
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchSourceList = async () => {
  try {
    const response = await axiosAPI.get("/sourceslist");
    return response;
  } catch (error) {
    throw error;
  }
};

const savePlanPolygonData = async (payload) => {
  try {
    const response = await axiosAPI.post("/plan", payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  deleteCognitoUser,
  getBasemapsData,createBasemap,updateBasemap,deleteBasemap,
  getCognitoUser,
  getCognitoUsers,
  updateCognitoUser,
  fetchProjects,
  updateProject,
  createProject,
  fetchCategories,
  fetchMapData,
  deleteProject,
  fetchModels,
  savePlanPolygonData,
  updatePlan,
  deletePlanPolygonData,
  getPlanPolygonData,
  getProject,
  batchModeling,
  getResult,
  fetchTileLayer,
  uploadGeotiff,
  getPlanWiseGeoTiffData,
  deletePlanWiseGeoTiffData,
  fileSourceUpload,
  checkResult,
  fetchSourceList,
  deleteSource,
  getBufferCircle,
  getAoi250Data,
};
