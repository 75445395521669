import { createSlice } from "@reduxjs/toolkit";

const polygonSlice = createSlice({
    name: "polygon",
    initialState: {
      uploadedPolygon: null,
    },
    reducers: {
      setUploadedPolygon: (state, action) => {
        state.uploadedPolygon = action.payload;
      },
      clearUploadedPolygon: (state) => {
        state.uploadedPolygon = null;
      },
    },
  });
  
  export const { setUploadedPolygon, clearUploadedPolygon } = polygonSlice.actions;
  export default polygonSlice.reducer;
  
