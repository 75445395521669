import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import styles from './style';

// Header Component
const Header = ({ projectName, currentDate }) => {
    return (
        <>
            <View style={styles.headerContainer}>
                <Text style={styles.header}>{projectName}</Text>
                <Text style={styles.date}>{currentDate}</Text>
            </View>
            <View style={styles.underline} />
        </>
    );
};

export default Header;
