import { addPolygonsToMap, getCenterOfPolygon } from "../../utils/functions";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

// Utility function to add legend and overlays to the canvas
const addLegendToCanvas = (ctx, canvas, scaleElement, planName) => {
    // Add scale control overlay
    if (scaleElement && scaleElement.textContent) {
        const scaleText = scaleElement.textContent;
        const scaleWidth = 100;
        const scaleHeight = 30;
        const xOffset = canvas.width - scaleWidth - 20;
        const yOffset = canvas.height - scaleHeight - 20;

        ctx.fillStyle = "white";
        ctx.fillRect(xOffset, yOffset, scaleWidth, scaleHeight);

        ctx.strokeStyle = "black";
        ctx.strokeRect(xOffset, yOffset, scaleWidth, scaleHeight);

        ctx.fillStyle = "black";
        ctx.font = "16px Arial bold";
        ctx.fillText(scaleText, xOffset + 5, yOffset + 22);
    } else {
        console.warn("Scale control not found or empty.");
    }

    // Add plan name as an overlay
    ctx.font = "16px Arial";
    ctx.fillStyle = "#1976D2";
    const textPadding = 5;
    const textWidth = ctx.measureText(planName).width + 2 * textPadding;
    const textHeight = 20;

    ctx.fillRect(10, 10, textWidth, textHeight);
    ctx.fillStyle = "white";
    ctx.fillText(planName, 15, 25);
};

// Main function
const downloadImagesToPdf = async (
    aoiPolygon,
    plans,
    selectedBasemap,
    aoiBufferCoords,
    planBufferCoords
) => {
    const center = getCenterOfPolygon(aoiPolygon.coordinates[0]);
    const imageUrls = [];

    for (let i = 0; i < plans.length; i++) {
        const plan = plans[i];
        let bufferCords =
            plan.name === "AOI" ? aoiBufferCoords : planBufferCoords;

            let map;
            try {
                map = new mapboxgl.Map({
                    container: document.createElement("div"),
                    style: `${selectedBasemap}`,
                    center: center,
                    zoom: 9,
                });
            } catch (err) {
                console.error("Error initializing map:", err);
                continue; // Skip this plan
            }            

        // Add scale control for the map
        const scaleControl = new mapboxgl.ScaleControl({
            maxWidth: 100,
            unit: "metric",
        });
        map.addControl(scaleControl, "top-right");

        await new Promise((resolve) => {
            map.on("load", () => {
                // Add polygons to the map
                addPolygonsToMap(map, plan.polygonsData , bufferCords, aoiPolygon);
            });

            map.on("idle", async () => {
                try{
                    // Add a delay to ensure all layers and polygons are rendered
                    await new Promise((res) => setTimeout(res, 1000));

                    // Capture the map canvas
                    const canvas = document.createElement("canvas");
                    const aspectRatio = map.getCanvas().width / map.getCanvas().height;
                    canvas.width = 800;
                    canvas.height = canvas.width / aspectRatio;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(map.getCanvas(), 0, 0, canvas.width, canvas.height);

                    // Add legend and overlays
                    const scaleElement = map
                        .getContainer()
                        .querySelector(".mapboxgl-ctrl-scale");
                    addLegendToCanvas(ctx, canvas, scaleElement, plan.langName);

                    // Save the image as a Data URL
                    const imageDataUrl = canvas.toDataURL("image/png");
                    imageUrls.push(imageDataUrl);
                    
                    

                } catch (err) {
                    console.error("Error during map rendering:", err);
                } finally {
                    if (map) {
                        map.remove();
                    }
                    resolve();
                }
            });
        });
    }

    return imageUrls;
};

export default downloadImagesToPdf;
