import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Grid,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  Typography,
  CircularProgress,
  Slide,
} from "@mui/material";
import {
  Clear as ClearIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { CheckCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AddProjectButton from "../common/AddButton";
import DonutChart from "../common/DonutChart";
import { fetchProjectsData, receiveProjectData } from "../../redux/slices/projectsSlice";
import { deleteProject } from "../../utils/carto";
import { useProjectsContext } from "../../Context/ProjectContext";

import "./ProjectList.css";
import { useSocket } from "../../Context/SocketContext";

const ProjectList = ({ langData, selectedShape, setSelectedShape }) => {
	const { data } = useSelector((state) => state.projects.receivedData) || {};
	const { updateProjects } = useProjectsContext();
	const navigate = useNavigate();
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [projectToDelete, setProjectToDelete] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchInput, setSearchInput] = useState("");
	const [fndProject, setFoundProject] = useState("");
	const projects = useSelector((state) => state.projects.projects);

	const dispatch = useDispatch();

	const [tooltipContent, setTooltipContent] = useState(null); // State for tooltip content
	const [showTooltip, setShowTooltip] = useState(false); // State to control tooltip visibility
	const [progress, setProgress] = useState(0);
	const [total, setTotal] = useState(0);
	const [fail, setFail] = useState(0);
	const socket = useSocket()

	const listItemRefs = useRef({});

	useEffect(() => {
		if (socket) {
		  socket.on("jobStatus", (data) => {
			const receivedProjectId = data.projectId;
	
			const foundProject = projects.find(
			  (project) => project.cartodb_id === receivedProjectId
			);
			dispatch(receiveProjectData({ projectId: receivedProjectId, data: data }));
	
			if (foundProject) {
			  const tooltip = `${langData.status}: ${data.status}, ${langData.message}: ${data.message}`;
			  setTooltipContent(tooltip);
			  setFoundProject(foundProject);
			  setProgress(data.completed);
			  setFail(data.failureCnt);
			  setTotal(data.total);
	
			  setShowTooltip(true);
	
			  setTimeout(() => {
				setShowTooltip(false);
				setTooltipContent(null);
			  }, 5000);
			}
		  });
		}
	
		return () => {
		  if (socket) socket.off("jobStatus");
		};
	  }, [projects, fndProject, showTooltip, tooltipContent, dispatch, socket]);
	

	useEffect(() => {
		dispatch(fetchProjectsData());
	}, [dispatch]);

	const handleSearchInputChange = (event) => {
		const inputValue = event.target.value;
		setSearchInput(inputValue);
	};

	const filteredProjects = useMemo(() =>
		projects?.filter((project) =>
		  project.name.toLowerCase().includes(searchInput.toLowerCase())
		),
		[projects, searchInput]
	  );
	  

	const handleDoubleClick = (project) => {
		//Handle project item click to select and deselect shapes from map.
		selectedShape == null || selectedShape !== project.cartodb_id ? setSelectedShape(project.cartodb_id) : setSelectedShape(null)
		updateProjects(project);
	};

	const handleDeleteClick = (project, event) => {
		event.stopPropagation();
		setProjectToDelete(project);
		setDeleteDialogOpen(true);
	};

	const handleConfirmDelete = async () => {
		if (projectToDelete) {
			try {
				setLoading(true);
				await deleteProject(projectToDelete.cartodb_id);
				dispatch(fetchProjectsData());
			} catch (error) {
				console.error("Error deleting project:", error);
			} finally {
				setLoading(false);
				setDeleteDialogOpen(false);
				setProjectToDelete(null);
				navigate("/");
			}
		}
	};

	const handleCloseDeleteDialog = () => {
		setDeleteDialogOpen(false);
		setProjectToDelete(null);
	};

	//Handle viewing project item in view port of list when clicked on shapes in map
	useEffect(() => {
		if (selectedShape && listItemRefs.current[selectedShape]) {
			// Scroll the selected item into view
			listItemRefs.current[selectedShape].scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	}, [selectedShape]);

	const handleNavigate = (projectId) => navigate(`/edit/${projectId}`);


	return (
		<Grid container className="grid-container">
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12} container justifyContent="center">
				<AddProjectButton langData={langData} />
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12} container justifyContent="center">
				<TextField
					label={langData.searchProjects}
					variant="outlined"
					fullWidth
					value={searchInput}
					onChange={handleSearchInputChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: searchInput && (
							<InputAdornment position="end">
								<IconButton onClick={() => setSearchInput("")} size="small">
									<ClearIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
					className="search-field-padding" // Add this class for padding
				/>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid
				item
				xs={12}
				className="custom-scrollbar"
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: '10px',
					minHeight: "calc(100vh - 300px)",
				}}
			>
				{filteredProjects?.length > 0 ? (
					filteredProjects?.map((project, index) => (
						<ListItem
							key={project.cartodb_id}
							ref={(el) => {
								listItemRefs.current[project.cartodb_id] = el;
							}}
							onClick={() => handleDoubleClick(project)}
							disablePadding={false}
							className={`list-item-custom ${index % 2 !== 0 ? "list-item-alt" : ""} ${selectedShape === project.cartodb_id ? "list-item-selected" : ""}`}
						>
							<ListItemText
								primary={
									<span className="project-name">
										<Tooltip title={project.name}>
											<span>{project.name}</span>
										</Tooltip>
									</span>
								}
							/>

							{project.cartodb_id === data?.projectId && (
								<React.Fragment>
									{progress < total ? (
										<>
											<DonutChart progress={progress} fail={fail} total={total} />
											<Typography>{`${progress}/${total}`}</Typography>
										</>
									) : (
										<CheckCircle />  // Assuming you import CheckCircleIcon correctly
									)}
								</React.Fragment>
							)}



							{/* Edit icon */}
							<IconButton
								color="red"
								onClick={() => handleNavigate(project.cartodb_id)}
								className="icon-button-transition"
							>
								<Tooltip title={langData.edit}>
									<EditIcon />
								</Tooltip>
							</IconButton>

							{/* Delete icon */}
							<IconButton
								color="red"
								onClick={(e) => handleDeleteClick(project, e)}
								className="icon-button-transition"
							>
								<Tooltip title={langData.delete}>
									<DeleteIcon sx={{ color: "#D30000" }} />
								</Tooltip>
							</IconButton>
						</ListItem>
					))
				) : (
					<Grid item xs={12}>
						<p>{langData.noProjectAvailable}</p>
					</Grid>
				)}
			</Grid>

			<Dialog
				open={deleteDialogOpen}
				onClose={handleCloseDeleteDialog}
				TransitionComponent={Slide}
				TransitionProps={{ direction: "up" }}
				PaperProps={{
					className: "dialog-paper-custom"
				}}
			>
				<DialogTitle>{langData.confirmDelete}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{langData.projectDeletionConfirmation}{" "}
						<span style={{ fontWeight: "bold", color: "red" }}>
							{projectToDelete?.name}
						</span>
						?
					</DialogContentText>
					{loading && <CircularProgress />}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDeleteDialog} color="primary">
						{langData.cancel}
					</Button>
					<Button
						onClick={handleConfirmDelete}
						color="secondary"
						disabled={loading}
						className="icon-button-transition"
					>
						{langData.delete}
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};

export default ProjectList;
