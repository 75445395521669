import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
} from '@mui/material';

const PolygonDialog = ({ isOpen, onClose, onFormSubmit,langData }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleFormSubmit = () => {
    onFormSubmit(selectedOption);
    setSelectedOption('');
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">{langData.selectAnOption}:</FormLabel>
          <RadioGroup
            aria-label="option"
            name="option"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <FormControlLabel value="treecover" control={<Radio />} label={langData.treeCover} />
            <FormControlLabel value="openwater" control={<Radio />} label={langData.openWater} />
            <FormControlLabel value="grassland" control={<Radio />} label={langData.grassLand} />
          </RadioGroup>
          <Button variant="contained" color="primary" onClick={handleFormSubmit}>
            {langData.submit}
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default PolygonDialog;
