import L from "leaflet";
import { fetchTileLayer } from "../carto";

const initializeMap = (container, center, zoom = 7) => {
  if (!container) {
    throw new Error("Map container not found");
  }

  const map = L.map(container).setView(center, zoom);
  return map;
};

const setupDrawControls = (map, drawnItems, isValidProject) => {
  const drawControlEnabled = new L.Control.Draw({
    edit: {
      featureGroup: drawnItems,
      edit: true,
      remove: true,
    },
    draw: {
      polygon: {
        shapeOptions: {
          color: "#FF00FF",
          fillColor: "#FF00FF",
          weight: 2,
        },
      },
      polyline: false,
      rectangle: false,
      circlemarker: false,
      circle: false,
      marker: false,
    },
  });

  const drawControlDisabled = new L.Control.Draw({
    edit: {
      featureGroup: drawnItems,
      edit: true,
      remove: true,
    },
    draw: {
      polygon: false,
      polyline: false,
      rectangle: false,
      circlemarker: false,
      circle: false,
      marker: false,
    },
  });

  if (isValidProject) {
    map.addControl(drawControlEnabled);
  }

  return { drawControlEnabled, drawControlDisabled };
};

const handleProjectGeometry = (project, map, drawnItems, drawControls) => {
  if (!project?.geom) return;

  let coordinates;
  if (typeof project.geom === "string") {
    coordinates = project.geom
      .replace("POLYGON((", "")
      .replace("))", "")
      .split(",")
      .map((pair) => {
        const [lng, lat] = pair.trim().split(" ").map(parseFloat);
        return [lat, lng];
      });
  } else {
    coordinates = project.geom.coordinates[0].map(([lng, lat]) => [lat, lng]);
  }

  if (coordinates.length > 0) {
    const polygonStyle = {
      fillColor: "#FF00FF",
      color: "#FF00FF",
      weight: 2,
    };
    const polygon = L.polygon(coordinates, polygonStyle);
    drawnItems.addLayer(polygon);
    map.removeControl(drawControls.drawControlEnabled);
    map.addControl(drawControls.drawControlDisabled);
    map.fitBounds(polygon.getBounds(), { padding: [20, 20] });
    const currentZoom = map.getZoom();
    map.setZoom(currentZoom - 1);
  }
};

const setupMapEventHandlers = (
  map,
  drawnItems,
  drawControls,
  project,
  onProjectDataChange,
  setIsLoadingProjectUpdate,
  setPopupMessage,
  langData,
  setShowPopup,
  isValidProject,
  updateProject
) => {
  map.on("draw:created", async (e) => {
    if (!isValidProject) {
      setPopupMessage(langData.saveProjectBeforeDrawing);
      setShowPopup(true);
      return;
    }

    const layer = e.layer;
    const polygonData = {
      geom: layer.toGeoJSON().geometry,
    };

    setIsLoadingProjectUpdate(true);
    try {
      const response = await updateProject(project.cartodb_id, polygonData);
      if (response.status === 200 && onProjectDataChange) {
        onProjectDataChange(response.data.data);
      }
    } catch (error) {
      console.error("Error creating polygon:", error);
    } finally {
      setIsLoadingProjectUpdate(false);
    }
  });

  map.on("draw:edited", async (e) => {
    const layers = e.layers;
    layers.eachLayer(async (layer) => {
      const polygonData = {
        geom: layer.toGeoJSON().geometry,
      };
      setIsLoadingProjectUpdate(true);
      try {
        const response = await updateProject(project.cartodb_id, polygonData);
        if (onProjectDataChange && response.status === 200) {
          onProjectDataChange(response.data.data);
        }
      } catch (error) {
        console.error("Error editing polygon:", error);
      } finally {
        setIsLoadingProjectUpdate(false);
      }
    });
  });

  map.on("draw:deleted", async (e) => {
    const layers = e.layers;
    layers.eachLayer(async (layer) => {
      const polygonData = {
        geom: null,
      };
      setIsLoadingProjectUpdate(true);
      try {
        const response = await updateProject(project.cartodb_id, polygonData);
        map.removeControl(drawControls.drawControlDisabled);
        map.addControl(drawControls.drawControlEnabled);
        if (onProjectDataChange && response.status === 200) {
          onProjectDataChange(response.data.data);
        }
      } catch (error) {
        console.error("Error Deleting polygon:", error);
      } finally {
        setIsLoadingProjectUpdate(false);
      }
    });
  });
};

const setupVectorTileLayer = async (map, tilesetId, getFillColor) => {
  try {
    const data = await fetchTileLayer(tilesetId);
    if (data) {
      L.vectorGrid
        .protobuf(data.data.url, {
          rendererFactory: L.canvas.tile,
          vectorTileLayerStyles: {
            default: function (properties, zoom) {
              let fillColor = getFillColor(properties.type);
              return {
                weight: 0,
                fillOpacity: 1,
                fill: true,
                fillColor: fillColor,
              };
            },
          },
        })
        .addTo(map)
        .on("tileerror", (error) => {
            console.error("Tilelayer error", error)
        })
    }
  } catch (error) {
    console.error("Error setting up vector tile layer:", error);
  }
};

export {
    initializeMap,
    setupDrawControls,
    handleProjectGeometry,
    setupMapEventHandlers,
    setupVectorTileLayer
}