/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Input,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { fileSourceUpload } from '../../utils/carto';
import { useSocket } from '../../Context/SocketContext';

const FileSelectDialog = ({ open, onClose, onSelectFile, onTableAdd, langData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [tableName, setTableName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [socketStatus, setSocketStatus] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const { projectId } = useParams();
  const socket = useSocket()

  useEffect(() => {
    socket.on('sourceStatus', (data) => {
      setSocketStatus(data.status);
      setShowLoader(true);
    });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setError(null);

    if (file) {
      const allowedFormats = ['.csv', '.geojson'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!allowedFormats.includes(`.${fileExtension}`)) {
        setError(langData.unsupportedFileFormat);
        setSelectedFile(null);
      }
    }
  };

  const handleClear = () => {
    setTableName('');
    setSelectedFile(null);
    setShowLoader(false);
    setError(null);
    setSocketStatus(null);
  };

  const handleConfirm = async () => {
    if (selectedFile && tableName) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('tableName', tableName);

        const response = await fileSourceUpload(formData);

        if (response.status === 200) {
          setSelectedFile(null);
          setSocketStatus(null);

          // Trigger the callback to add the table name to sources
          onTableAdd(tableName);

          onClose();
        } else {
          setError(langData.uploadFailed);
        }
      } catch (error) {
        console.log('Error during file upload:', error.response?.data?.error);
        setError(error.response?.data?.error || langData.unexpectedErrorOccured);
      } finally {
        setLoading(false);
      }
    } else {
      setError(langData.pleaseSelectFile);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 16,
          overflow: 'hidden',
        },
      }}
    >
      <Box
        sx={{
          background: 'rgb(25,118,210)',
          color: 'white',
          padding: '16px',
          textAlign: 'center',
          borderRadius: '16px 16px 0 0',
        }}
      >
        <Typography variant="h6">{langData.selectFileFromSystem}</Typography>
      </Box>
      <DialogContent>
        <Box mb={1}>
          <Typography variant="body2" color="textSecondary">
            {langData.acceptedFormats}
          </Typography>
        </Box>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <Input
            id="table-name"
            type="text"
            placeholder={langData.enterTableName}
            value={tableName}
            onChange={(e) => setTableName(e.target.value)}
            disableUnderline
            sx={{
              border: '1px solid #ccc',
              borderRadius: 0,
              padding: '8px',
              fontSize: '14px',
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <Input
            id="file-upload"
            type="file"
            accept=".csv, .geojson, .xlsx"
            onChange={handleFileChange}
            fullWidth
            style={{ display: 'none' }}
          />
          <label htmlFor="file-upload">
            <Button component="span" variant="outlined" color="primary">
              {langData.browse}
            </Button>
          </label>
        </FormControl>
        {selectedFile && (
          <Box mt={1}>
            <Typography variant="body1">
              {langData.selectedFile}: {selectedFile.name}
            </Typography>
          </Box>
        )}
        {error && (
          <Box mt={1}>
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          </Box>
        )}
        
        {socketStatus && (
          <Box mt={2}>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
              <strong>Status of {tableName} {langData.table}:</strong> 
              {socketStatus === 'success' ? (
                <>
                  <span style={{ color: 'green', marginLeft: 1 }}>{langData.success}</span>
                  <CheckCircleIcon sx={{ color: 'green', marginLeft: 1 }} />
                </>
              ) : (
                <>
                  {showLoader && (
                    <>
                      <span style={{ marginRight: 1 }}>{socketStatus}...</span>
                      <CircularProgress size={16} color="inherit" />
                    </>
                  )}
                  {socketStatus !== 'success' && !showLoader && (
                    <span style={{ color: 'red', marginLeft: 1 }}>{socketStatus}</span>
                  )}
                </>
              )}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClear} color="secondary">
          {langData.clear}
        </Button>
        <Button onClick={onClose} color="secondary">
          {langData.close}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={loading || !!error}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : langData.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileSelectDialog;
