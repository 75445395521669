import React, { useState, useEffect, useCallback, useMemo } from "react";
import ProjectEditForm from "../Components/Project/ProjectDetails";
import ProjectMapComponent from "../Components/Map/AoiMapComponent";
import PlanMapComponent from "../Components/Map/PlanMapComponent";
import CircularProgress from "@mui/material/CircularProgress";
import { useProjectsContext } from "../Context/ProjectContext";
import { getPlanPolygonData, getPlanWiseGeoTiffData } from "../utils/carto";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import '../resources/customscroller.css'
import { checkResult } from "../utils/carto";
import { useParams } from "react-router-dom";
import { IconLayoutSidebarLeftExpand, IconLayoutSidebarRightExpand } from '@tabler/icons-react';
import { setDataChanged } from "../redux/slices/dataChangeSlice";
import { setGeotiffDataInStore } from "../redux/slices/polydataSlice";

const MemoizedProjectEditForm = React.memo(ProjectEditForm);
const MemoizedProjectMapComponent = React.memo(ProjectMapComponent);
const MemoizedPlanMapComponent = React.memo(PlanMapComponent);

const EditPage = ({ method , langData}) => {
  const { projectsData, updateProjects, fetchSingleProject, isLoading: contextLoading } = useProjectsContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { projectId } = useParams();
  const [resultButton, setResultButton] = useState(true); 
  const [isFormCollapsed, setIsFormCollapsed] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [tabSwitchInProgress, setTabSwitchInProgress] = useState(false);
  const [planData, setPlanData] = useState({});
  const [geotiffData, setGeoTiffData] = useState({});
  const dispatch = useDispatch();
  const dataChanged = useSelector((state) => state.dataChanged);

  const isProjectsArray = useMemo(() => Array.isArray(projectsData), [projectsData]);

  const memoizedFetchResult = useCallback(async () => {
    try {
      if (projectId) {
        const response = await checkResult(projectId);
        // Remove duplicates based on model_id
        if (response.data.result_exists) {
          setResultButton(false);
        }
      }
    } catch (error) {
      console.error("Error checking result:", error);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      memoizedFetchResult();
    }
  }, [projectId, memoizedFetchResult]);

  useEffect(() => {
    const loadInitialData = async () => {      
      if (projectId && (!projectsData?.project_id || projectsData.project_id !== projectId)) {
        await fetchSingleProject(projectId);
      }
    };
    
    loadInitialData();
  }, [projectId, projectsData.project_id, fetchSingleProject]);

  // Modified loading state to consider both local and context loading
  useEffect(() => {
    if (!contextLoading && projectsData?.project_id) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [contextLoading, projectsData]);

  useEffect(() => {
    if (!projectsData.project_id || !selectedTab) return
    
    const fetchGeoTiffData = async (projectId, newValue) => {
      try {
        if (projectId) {
          const response = await getPlanWiseGeoTiffData(projectId, newValue);
          setGeoTiffData((prevData) => ({
            ...prevData,
            [newValue]: response.data,
          }));
          dispatch(setGeotiffDataInStore({ [newValue]: response.data }));
        }
      } catch (error) {
        console.error("Error fetching geotiff data:", error);
      }
    };
    
    fetchGeoTiffData(projectsData.project_id, selectedTab);
  }, [selectedTab, projectsData.project_id, dispatch]);

  useEffect(() => {
    const fetchPlanData = async (newValue) => {
      try {
        
        
        const response = await getPlanPolygonData(
          projectsData.project_id,
          newValue
        );
        setPlanData((prevData) => ({
          ...prevData,
          [newValue]: response.data,
        }));
      } catch (error) {
        console.error("Error fetching plan data:", error);
      }
    };

    if (dataChanged) {
      const newValueOptions = [1, 2, 3];
      newValueOptions.forEach((newValue) => {
        fetchPlanData(newValue);

        dispatch(setDataChanged(false));
      });
    
    }

    // Fetch GeoTiffData every time selectedTab changes
    setIsLoading(false);
  }, [projectsData.project_id, selectedTab, dataChanged, dispatch]);

  const handleTabChange = useCallback(
    (newValue) => {
      if (tabSwitchInProgress) {
        return;
      }

      setTabSwitchInProgress(true);
      setIsLoading(true);

      setTimeout(() => {
        setSelectedTab(newValue);
        setTabSwitchInProgress(false);
      }, 100);
    },
    [tabSwitchInProgress]
  );

  const handleProjectDataChange = useCallback(async (data) => {
    setIsLoading(true);
    setUpdateInProgress(true);

    updateProjects(data);
    setTimeout(() => {
      setIsLoading(false);
      setUpdateInProgress(false);
    }, 1000);
  }, [updateProjects]);

  const toggleFormCollapse = () => {
    setIsFormCollapsed((prev) => !prev);
  };

  const renderContent = useMemo(() => {
    if (isLoading) {
      return (<div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {langData.loading}
      </div>)
    }
    if (selectedTab === 0) {
      return (
        <MemoizedProjectMapComponent
          onProjectDataChange={handleProjectDataChange}
          showProjectList={false}
          project={isProjectsArray ? null : projectsData}
          langData={langData}
          isFormCollapsed={isFormCollapsed}
        />
      );
    }
    return (
      <MemoizedPlanMapComponent
        selectedTab={selectedTab}
        project={isProjectsArray ? null : projectsData}
        planData={planData}
        geotiffData={geotiffData[selectedTab]}
        langData={langData}
      />
    );
  }, [isLoading, selectedTab, isProjectsArray, projectsData, planData, geotiffData, langData, handleProjectDataChange, isFormCollapsed]);

  return (
    <div style={{ display: "flex", height: 'calc(100% - 40px)' }}>
      <div
        style={{
          flex: isFormCollapsed ? "0 0 auto" : "1 1 25%",
          overflow: "auto",
          padding: isFormCollapsed ? "0" : "20px",
          transition: "flex 0.3s ease-in-out, padding 0.3s ease-in-out",
          height: '100%'
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={toggleFormCollapse}>
            {isFormCollapsed ? <IconLayoutSidebarLeftExpand /> : <IconLayoutSidebarRightExpand />}
          </IconButton>
          {isFormCollapsed || !isProjectsArray ? (
            updateInProgress && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <span style={{ marginRight: "10px" }}>
                  {langData.updateInProgress}
                </span>
                <CircularProgress size={20} />
              </div>
            )
          ) : (
            <p></p>
          )}
        </div>
        {!isFormCollapsed && (
          <MemoizedProjectEditForm
            onProjectDataChange={handleProjectDataChange}
            setSelectedTab={handleTabChange}
            projects={projectsData}
            method={method}
            plan={selectedTab}
            langData = {langData}
            buttonState ={resultButton}
            memoizedFetchResult= {memoizedFetchResult}
            setResultButton= {setResultButton}
          />
        )}
      </div>
      <div style={{ flex: "1 1 75%", position: "relative" }}>
      {renderContent}
      </div>
    </div>
  );
};

export default EditPage;
