/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "./Components/common/Navbar";
import { Authenticator } from "@aws-amplify/ui-react";
import { CircularProgress, Button } from "@mui/material";
import enData from "./locales/en.json";
import jpData from "./locales/ja.json";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import routes from "./routes"; // Import routes function
import MapContextProvider from "./Context/MapContext";
import { ProjectsProvider } from "./Context/ProjectContext";
import { Provider } from "react-redux";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import {store} from "./redux/store"
import { Hub } from "aws-amplify/utils";
import { getCognitoUser } from "./utils/carto";
import ErrorMessage from "./Components/common/ErrorMessage";



I18n.putVocabularies(translations);

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState("ja");
  const [langData, setLangData] = useState(currentLanguage === "en" ? enData : jpData);
  const [loadingMessage, setLoadingMessage] = useState(langData.initializingTheApp)
  const [loading, setLoading] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [isEnabled, setIsEnabled] = useState(() => {
    const storedIsEnabled = localStorage.getItem("isEnabled");
    return storedIsEnabled === "true";
  });
  const [isAdmin, setIsAdmin] = useState(() => {
    const storedIsAdmin = localStorage.getItem("isAdmin");
    return storedIsAdmin === "true";
  });
  const [shouldRender, setShouldRender] = useState(false);
  const [showPermissionDenied, setShowPermissionDenied] = useState(false);

  useEffect(() => {
    I18n.putVocabularies({
      en: {
        "Preferred Username": "Preferred Username",
        "Enter your Preferred Username": "Enter your Preferred Username",
      },
      ja: {
        "Preferred Username": "優先ユーザ名",
        "Enter your Preferred Username": "お好みのユーザー名を入力してください",
      },
    });
    I18n.setLanguage(currentLanguage);

    const params = new URLSearchParams(window.location.search);
    const langParam = params.get("lang");
    if (langParam && (langParam === "en" || langParam === "ja")) {
      setCurrentLanguage(langParam);
      params.delete("lang");
      const newUrl = `${window.location.pathname}${
        params.toString() ? `?${params.toString()}` : ""
      }`;
      window.history.replaceState({}, "", newUrl);
    }
    setLangData(currentLanguage === "en" ? enData : jpData);

    if (!isEnabled) {
      setTimeout(() => {
        setShowPermissionDenied(true);
      }, 1500); // 1.5 seconds delay
    }

    localStorage.setItem("isEnabled", "true");
    localStorage.setItem("isAdmin", "true");
    const authListener = ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          setIsUserAuthenticated(true);
          fetchUserData();
          break;
        case "signedOut":
          setIsUserAuthenticated(false);
          localStorage.removeItem("isEnabled");
          localStorage.removeItem("isAdmin");
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", authListener);
    fetchUserData(); // Ensure data is fetched when the app initializes

    return () => {
      // Hub.remove("auth", authListener);
    };
  }, [currentLanguage, isEnabled]);

  const fetchCurrentUser = async (username) => {
    try {
      const attributes = await getCognitoUser(username);
      return attributes.UserAttributes;
    } catch (error) {
      console.error("Error fetching Cognito user:", error);
      return [];
    }
  };

  const fetchUserData = async () => {
    setLoading(true);
    setShouldRender(false);
    try {
      const user = await getCurrentUser();
      const userAttributes = await fetchCurrentUser(user?.username);
      setCurrentUser({ ...user, attributes: userAttributes });
      const isEnabledValue =
        userAttributes.find((attr) => attr.Name === "custom:isEnabled")
          ?.Value === "true";
      const isAdminValue = userAttributes.find(
        (attr) => attr.Name === "custom:isAdmin"
      )?.Value === "true";

      if (isEnabledValue !== undefined) {
        setIsEnabled(isEnabledValue);
        localStorage.setItem("isEnabled", isEnabledValue);
      }

      if (isAdminValue !== undefined) {
        setIsAdmin(isAdminValue);
        localStorage.setItem("isAdmin", isAdminValue);
      }
      setShowPermissionDenied(!isEnabledValue);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setShouldRender(true);
      setLoading(false); // Set loading to false after fetchUserData completes
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <Provider store={store}>
      <MapContextProvider>
        <ProjectsProvider>
          {loading ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              flexDirection: 'column'
            }}>
              <CircularProgress />
              <div style={{ marginTop: '20px', fontSize: '16px', color: '#555' }}>
              {loadingMessage}
              </div>
            </div>
          ) : shouldRender ? (
            <Router>
              <Authenticator
                initialState="signIn"
                loginMechanisms={["email"]}
                signUpAttributes={["email", "preferred_username"]}
              >
                {showPermissionDenied && (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 9999,
                      color: "#000",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <h2>{langData.permissionDenied}</h2>
                      <p>
                        {langData.noPermissionWaitForApproval}
                      </p>
                      <Button variant="contained" onClick={handleSignOut}>
                        {langData.signOut}
                      </Button>
                    </div>
                  </div>
                )}
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress />
                    {langData.loading}
                  </div>
                ) : (
                  <div
                    style={{
                      height: "100vh",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <Navbar isAdmin={isAdmin} langData={langData} /> */}
                    <div
                      style={{
                        flex: 1,
                        overflowY: "hidden",
                        width: "100%",
                        height: "calc(100% - 64px)",
                      }}
                    >
                      <Routes>{routes(isAdmin, langData,currentLanguage)}</Routes>
                    </div>
                  </div>
                )}
              </Authenticator>
            </Router>
          ) : null}
        </ProjectsProvider>
      </MapContextProvider>
      <ErrorMessage langData={langData}/>
    </Provider>
  );
}

export default App;
