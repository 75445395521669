import React, { useRef, useEffect, useState, useMemo } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import mapboxgl from "mapbox-gl";
import "leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled";
import { updateProject } from "../../utils/carto";
import { useSelector } from "react-redux";
import { getFillColor } from "../../utils/functions";
import { createTileLayer } from "../../utils/createTileLayer";
import {
  initializeMap,
  setupDrawControls,
  handleProjectGeometry,
  setupMapEventHandlers,
  setupVectorTileLayer,
} from "../../utils/aoiMap/utils";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const AoiMapComponent = ({
  showProjectList,
  project,
  onProjectDataChange,
  langData,
  isFormCollapsed,
}) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProjectUpdate, setIsLoadingProjectUpdate] = useState(false);
  const [isValidProject, setIsValidProject] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const selectedBasemap = useSelector((state) => state.basemap.selectedBasemap) || 'mapbox://styles/mapbox/streets-v11';
  const center = useMemo(() => [35.68334155120243, 139.7670395118941], []);

  useEffect(() => {
    try {
      const mapContainer = mapContainerRef.current;
      const map = initializeMap(mapContainer, center);
      mapRef.current = map;

      const tileLayer = createTileLayer(L, mapboxgl.accessToken, selectedBasemap);
      tileLayer.addTo(map);

      const handleResize = () => {
        if (map) {
          map.invalidateSize();
        }
      };

      const observer = new ResizeObserver(handleResize);
      observer.observe(mapContainer);

      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);

      const drawControls = setupDrawControls(map, drawnItems, isValidProject);

      setupMapEventHandlers(
        map,
        drawnItems,
        drawControls,
        project,
        onProjectDataChange,
        setIsLoadingProjectUpdate,
        setPopupMessage,
        langData,
        setShowPopup,
        isValidProject,
        updateProject
      );

      if (project) {
        setupVectorTileLayer(map, "ue_lc_kotoku2_tileset3", getFillColor);
        handleProjectGeometry(project, map, drawnItems, drawControls);
      }

      setIsLoading(false);

      return () => {
        observer.disconnect();
        map.remove();
      };
    } catch (error) {
      console.error("Error in AoiMapComponent:", error);
      setIsLoading(false);
      setIsLoadingProjectUpdate(false);
      setShowPopup(true);
      setPopupMessage(langData.mapLoadingError);
    }
  }, [project, isValidProject, isFormCollapsed, center, selectedBasemap, onProjectDataChange]);

  useEffect(() => {
    if (!mapRef.current || !selectedBasemap) return;
    const newTileLayer = createTileLayer(L, mapboxgl.accessToken, selectedBasemap);
    newTileLayer.addTo(mapRef.current);
  }, [selectedBasemap]);

  useEffect(() => {
    setIsValidProject(
      project && project.cartodb_id !== undefined && project.cartodb_id !== null
    );
  }, [project]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ display: "flex", flexGrow: 1, position: "relative" }}>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </div>
        )}
        {isLoadingProjectUpdate && (
          <Dialog
            open={isLoadingProjectUpdate}
            onClose={() => setIsLoadingProjectUpdate(false)}
          >
            <DialogTitle>{langData.loading}</DialogTitle>
            <DialogContent>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress color="primary" />
              </div>
            </DialogContent>
          </Dialog>
        )}
        <div ref={mapContainerRef} style={{ flex: "1", position: "relative", overflow: "hidden" }} />
      </div>
      <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
        <DialogTitle>{langData.error}</DialogTitle>
        <DialogContent>
          <p>{popupMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPopup(false)} color="primary">
            {langData.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AoiMapComponent;