import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearError } from '../../redux/slices/errorSlice';
import { Snackbar, Alert } from '@mui/material';

const ErrorMessage = ({langData}) => {
    const { message } = useSelector((state) => state.error);
    const dispatch = useDispatch();

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') return;
        dispatch(clearError());
    };

    return (
        <Snackbar
            open={!!message} 
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        >
            <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: '100%' }}
            >
                {langData[message]}
            </Alert>
        </Snackbar>
    );
};

export default ErrorMessage;
