import React, { useState, useEffect } from 'react';
import {
  Typography, TableContainer, Table, TableHead, TableRow, TableCell,
  TableBody, IconButton, Paper, Box, InputBase, Dialog, DialogActions,
  DialogContent, DialogTitle, Button, TextField, CircularProgress, Link, Tooltip, Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { getBasemapsData, createBasemap, updateBasemap, deleteBasemap } from '../../utils/carto';
import { setBasemapsFromDB } from '../../redux/slices/basemapSlice';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BasemapsTab = ({ langData }) => {
  const dispatch = useDispatch();
  const basemaps = useSelector(state => state.basemap.basemaps);
  const [filteredBasemaps, setFilteredBasemaps] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [currentBasemap, setCurrentBasemap] = useState({ id: '', title: '', link: '', jplabel: '', enlabel: '' });
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchBasemaps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredBasemaps(
      basemaps.filter(basemap =>
        basemap?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
        basemap?.jplabel?.toLowerCase().includes(searchText.toLowerCase()) ||
        basemap?.enlabel?.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, basemaps]);

  const fetchBasemaps = async () => {
    try {
      const response = await getBasemapsData();
      dispatch(setBasemapsFromDB(response.data));
    } catch (error) {
      console.error("Error fetching basemaps:", error);
    }
  };

  const handleOpenDialog = (basemap) => {
    setCurrentBasemap(basemap || { id: '', title: '', link: '', jplabel: '', enlabel: '' });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentBasemap(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { id, title, link, jplabel, enlabel } = currentBasemap;
    if (!title || !link || !jplabel) {
      setSnackbarMessage(langData["titleLinkJpLabelEmpty"]);
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    if (!validateURL(link)) {
      setSnackbarMessage(langData["invalidURL"]);
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    const basemapData = { title, link, jplabel, enlabel };
    try {
      if (id) {
        await updateBasemap(id, basemapData);
      } else {
        await createBasemap(basemapData);
      }
      fetchBasemaps();
      handleCloseDialog();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Error saving basemap:", error);
        setSnackbarMessage(langData.alreadyExistsBaseMap);
        setSnackbarOpen(true);
        setLoading(false);
        return;
      } else {
        // Handle other errors
        console.error("Error saving basemap:", error);
      }
    }
    setLoading(false);
  };

  const validateURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleDeleteBasemap = async () => {
    setDeleteLoading(true);
    try {
      await deleteBasemap(deleteId);
      fetchBasemaps();
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting basemap:", error);
    }
    setDeleteLoading(false);
  };

  const renderBasemapGridItems = () => {
    return (
      <TableContainer component={Paper} style={{ marginBottom: 16 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{langData["title"]}</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{langData["link"]}</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{langData["jplabel"]}</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{langData["enlabel"]}</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{langData["actions"]}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBasemaps.map((basemap, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
                <TableCell>{basemap.title}</TableCell>
                <TableCell><Link href={basemap.link} target="_blank" rel="noopener">{basemap.link}</Link></TableCell>
                <TableCell>{basemap.jplabel}</TableCell>
                <TableCell>{basemap.enlabel}</TableCell>
                <TableCell>
                  <Tooltip title={langData["edit"]}>
                    <IconButton onClick={() => handleOpenDialog(basemap)} color="primary">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={langData["delete"]}>
                    <IconButton onClick={() => { setDeleteDialogOpen(true); setDeleteId(basemap.id); }} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div style={{ padding: 16, minHeight: 300, flex: 1, display: "flex", flexDirection: "column" }}>
      <Box marginBottom={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" gutterBottom>{langData["basemapHeading"]}</Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputBase
            placeholder={langData["searchPlaceholder"]}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            startAdornment={<Typography variant="subtitle2" style={{ marginRight: 8 }}>{langData["searchLabel"]}</Typography>}
            endAdornment={
              <IconButton onClick={() => setSearchText("")} color="primary" style={{ marginLeft: 8 }}>
              <SearchIcon />
            </IconButton>
            }
          />
          <Button startIcon={<AddIcon />} onClick={() => handleOpenDialog(null)} variant="contained" color="primary" style={{ marginLeft: 8 }}>
            {langData["addBasemap"]}
          </Button>
        </div>
      </Box>
      {renderBasemapGridItems()}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ fontWeight: 'bold' }}>{currentBasemap.id ? langData["editBasemap"] : langData["addBasemap"]}</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <TextField
                margin="dense"
                name="title"
                label={langData["title"]}
                fullWidth
                value={currentBasemap.title}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="link"
                label={langData["link"]}
                fullWidth
                value={currentBasemap.link}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="jplabel"
                label={langData["jplabel"]}
                fullWidth
                value={currentBasemap.jplabel}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="enlabel"
                label={langData["enlabel"]}
                fullWidth
                value={currentBasemap.enlabel}
                onChange={handleChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">{langData["cancel"]}</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">{langData["submit"]}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>{langData["confirmDelete"]}</DialogTitle>
        <DialogContent>
          {deleteLoading ? (
            <CircularProgress />
          ) : (
            <Typography>{langData["confirmDeleteMessage"]}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} disabled={deleteLoading}>{langData["cancel"]}</Button>
          <Button onClick={handleDeleteBasemap} color="primary" disabled={deleteLoading}>{langData["confirm"]}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BasemapsTab;
