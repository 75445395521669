import Chart from "chart.js/auto";

const generateChartImage = async (aoisum, plan1sum, plan2sum, plan3sum) => {
    const chartCanvas = document.createElement("canvas");
    chartCanvas.width = 400;
    chartCanvas.height = 300;

    document.body.appendChild(chartCanvas);

    const ctx = chartCanvas.getContext("2d");
    new Chart(ctx, {
        type: "bar",
        data: {
            labels: ["AOI", "Plan 1", "Plan 2", "Plan 3"],
            datasets: [
                {
                    label: "HSI", // Added label here
                    data: [aoisum, plan1sum, plan2sum, plan3sum],
                    backgroundColor: ["#8884d8", "#82ca9d", "#ffc658", "#ff7300"],
                    borderColor: ["#8A2BE2", "#98FB98", "#FFD700", "#FF8C00"],
                    borderWidth: 1,
                },
            ],
        },
        options: {
            indexAxis: 'y',
            scales: {
                x: {
                    beginAtZero: true,
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            size: 20 // Set the font size for the x-axis labels
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: 20 // Set the font size for the y-axis labels
                        }
                    }
                }
            },
        },
    });

    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for chart to render

    const chartImageSrc = chartCanvas.toDataURL("image/png");

    document.body.removeChild(chartCanvas);

    return chartImageSrc;
};

export default generateChartImage;