import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import styles from './style';

const Legend = () => {
    return (
        <View style={styles.legendContainer}>
            <View style={styles.legendItem}>
                <View style={[styles.legendBlock, { backgroundColor: 'darkgreen' }]}></View>
                <Text style={styles.legendText}>森林</Text>
            </View>
            <View style={styles.legendItem}>
                <View style={[styles.legendBlock, { backgroundColor: 'green' }]}></View>
                <Text style={styles.legendText}>草地</Text>
            </View>
            <View style={styles.legendItem}>
                <View style={[styles.legendBlock, { backgroundColor: 'red' }]}></View>
                <Text style={styles.legendText}>低木</Text>
            </View>
            <View style={styles.legendItem}>
                <View style={[styles.legendBlock, { backgroundColor: 'blue' }]}></View>
                <Text style={styles.legendText}>水域</Text>
            </View>
        </View>
    );
};

export default Legend;
