import { Document } from '@react-pdf/renderer';
import FirstPage from './FirstPage';
import ImagePage from './ImagePages';

const ReportPdf = (props) => {

  return (
    <Document>
      <FirstPage {...props} />
      <ImagePage
        projectName={props.projectName}
        images={props.images}
      />
    </Document>
  );
};

export default ReportPdf;
