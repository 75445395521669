import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  LinearProgress,
  Box,
  FormControl,
  Input,
} from "@mui/material";

import { uploadGeotiff } from "../../utils/carto";
import { fromArrayBuffer } from "geotiff";
import { setDataChanged } from "../../redux/slices/dataChangeSlice"; 
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const UploadGeoTIFFDialog = ({ open, onClose, planId, langData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [uploadError, setUploadError] = useState(null);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!open) {
      setSelectedFile(null);
    }
  }, [open]);

  const handleUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();

      if (selectedFile) {
        const metadata = await readGeoTIFFMetadata(selectedFile);
        formData.append("file", selectedFile);
        formData.append("metadata", JSON.stringify(metadata));
      }

      const response = await uploadGeotiff(formData);

      if (response) {
        console.log("File uploaded successfully");
        dispatch(setDataChanged(true));
        // Handle success, e.g., show a success message to the user
      } else {
        console.error("File upload failed");
        setUploadError(langData.uploadFaildError);
        // Handle failure, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadError(`${langData.uploadError}: ${error}`);
      // Handle other errors, e.g., network issues
    } finally {
      setUploading(false);
    }

    // onClose();
  };

  const handleClose = () => {
    // Reset the uploadError state to null when the cancel button is clicked
    setUploadError(null);
    onClose();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    try {
      if (
        file &&
        (file.type === "image/tiff" ||
          file.name.endsWith(".tif") ||
          file.name.endsWith(".tiff"))
      ) {
        setSelectedFile(file);
        setError(null); // Clear any previous errors
      } else {
        throw new Error(
          langData.invalidFileFormatError
        );
      }
    } catch (error) {
      console.error("Error selecting file:", error);
      setSelectedFile(null);
      setError(error.message); // Set the error message for display
    }
  };

  // Function to read metadata from GeoTIFF file
  const readGeoTIFFMetadata = async (file) => {
    const arrayBuffer = await readFileAsArrayBuffer(file);
    const tiff = await fromArrayBuffer(arrayBuffer);
    const image = await tiff.getImage();
    const metadata = {
      planId: planId,
      projectId: projectId,
      fileName: file.name,
      width: image.getWidth(),
      height: image.getHeight(),
      tileWidth: image.getTileWidth(),
      tileHeight: image.getTileHeight(),
      samplesPerPixel: image.getSamplesPerPixel(),
      origin: image.getOrigin(),
      resolution: image.getResolution(),
      bbox: image.getBoundingBox(),
    };
    return metadata;
  };

  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 16,
          overflow: "hidden",
        },
      }}
    >
      <Box>
        <Box
          sx={{
            background: "rgb(25,118,210)",
            color: "white",
            padding: "16px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">{langData.uploadGeoTIFF}</Typography>
        </Box>
        <DialogContent>
          <Box mb={1}>
            <Typography variant="body2" color="textSecondary">
              {langData.uploaderNoteTIFF}
            </Typography>
          </Box>
          <FormControl fullWidth>
            <Input
              id="geo-tiff-upload"
              type="file"
              accept=".tif, .tiff"
              onChange={handleFileChange}
              fullWidth
              style={{ display: "none" }}
            />
            <label htmlFor="geo-tiff-upload">
              <Button component="span" variant="outlined" color="primary">
                {langData.browse}
              </Button>
            </label>
          </FormControl>

          {selectedFile && (
            <Box mt={1}>
              <Typography variant="body2">
                {langData.selectedFile}: {selectedFile.name}
              </Typography>
            </Box>
          )}

          {uploadError && (
            <Box mt={1}>
              <Typography variant="body2" color="error">
                {uploadError}
              </Typography>
            </Box>
          )}

          {error && (
            <Box mt={1}>
              <Typography variant="body2" color="error">
                {langData.error}: {error}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2" color="textSecondary">
            {uploading && `${langData.uploadInProgress}...`}
          </Typography>
          <Box>
            <Button onClick={handleClose} color="secondary">
              {langData.cancel}
            </Button>
            <Button
              onClick={handleUpload}
              variant="contained"
              color="primary"
              disabled={!selectedFile}
              size="small"
            >
              {langData.upload}
            </Button>
          </Box>
        </Box>

        {uploading && (
          <LinearProgress color="primary" style={{ marginTop: 2 }} />
        )}
      </Box>
    </Dialog>
  );
};

export default UploadGeoTIFFDialog;
