import mapboxgl from "mapbox-gl";
import { getCenterOfPolygon } from "../../utils/functions";

mapboxgl.accessToken =
    process.env.REACT_APP_MAPBOX_TOKEN;


const processBufferArray = async (
    bufferArray,
    selectedBasemap,
    aoiPolygon,
) => {
    const imageUrls = [];

    for (let i = 0; i < bufferArray.length; i++) {
        await new Promise(async (resolve, reject) => {
            let bufferMap;
            try {
                bufferMap = createBufferMap(
                    selectedBasemap,
                    aoiPolygon.coordinates[0],
                    12
                );

                await addPolygonOutlineToMap(bufferMap, aoiPolygon);
                await addBufferPolygonToBufferMap(
                    bufferMap,
                    bufferArray[i].polygonsData
                );
                await addTileLayerToBufferMap(bufferMap);

                bufferMap.on("idle", () => {
                    const canvas = document.createElement("canvas");
                    const aspectRatio =
                        bufferMap.getCanvas().width / bufferMap.getCanvas().height;
                    canvas.width = 800;
                    canvas.height = canvas.width / aspectRatio;
                    const ctx = canvas.getContext("2d");

                    // Draw map on canvas
                    ctx.drawImage(bufferMap.getCanvas(), 0, 0, canvas.width, canvas.height);

                    const canvasUrl = canvas.toDataURL("image/jpeg");
                    imageUrls.push({
                        name: bufferArray[i].name || `Buffer ${i + 1}`,
                        dataUrl: canvasUrl,
                    });
                });
            } catch (error) {
                console.log("Error processing buffer array:", error);
                reject(error);
            } finally {
                bufferMap.remove();
                resolve();
            }
        });
    }

    return imageUrls;
};

const createBufferMap = (selectedBasemap, coordinates, zoomLevel) => {
    const map = new mapboxgl.Map({
        container: document.createElement("div"), 
        style: `${selectedBasemap}`,
        center: getCenterOfPolygon(coordinates),
        zoom: zoomLevel,
        maxZoom: 20,
        preserveDrawingBuffer: true
    });

    map.on('load', () => {
        // eslint-disable-next-line no-unused-vars
        const canvasUrl = map.getCanvas().toDataURL();
    });

    const scaleControl = new mapboxgl.ScaleControl({
        maxWidth: 100,
        unit: "metric",
    });
    map.addControl(scaleControl, "top-right");
    return map;
};


const addPolygonOutlineToMap = (map, polygonGeoJson) => {
    map.on("load", () => {
        map.addLayer({
            id: `polygon-outline-layer-${polygonGeoJson.coordinates[0][0][0]}-${polygonGeoJson.coordinates[0][0][1]}`, // Unique layer ID for each polygon outline
            type: "line",
            source: {
                type: "geojson",
                data: polygonGeoJson,
            },
            paint: {
                "line-color": "#000", // Red color outline
                "line-width": 2, // Adjust line width as needed

            },
        });
    });
};

const addBufferPolygonToBufferMap = async (map, polygonCoords) => {
    if (polygonCoords) {
        const bufferGeoJson = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: polygonCoords[0],
            },
            properties: {
                description: "Buffer Polygon",
            },
        };

        // Convert the style loading check into a promise-based approach
        await new Promise((resolve, reject) => {
            if (map.isStyleLoaded()) {
                resolve();  // Resolve immediately if the style is already loaded
            } else {
                map.once("style.load", resolve);  // Resolve on style load
            }
        });

        // After the style is confirmed to be loaded, add the layer
        addLayerToMap(map, bufferGeoJson, polygonCoords);
    }
};


const addLayerToMap = (map, bufferGeoJson, polygonCoords) => {
    map.addLayer({
        id: `buffer-layer-${polygonCoords[0][0]}-${polygonCoords[0][1]}`,
        type: "line",
        source: {
            type: "geojson",
            data: bufferGeoJson,
        },
        paint: {
            "line-color": "#000",
            "line-width": 3,
        },
    });
};

const addTileLayerToBufferMap = (bufferMap) => {
    return new Promise((resolve, reject) => {
        bufferMap.on("load", () => {


            try {
                bufferMap.addSource("tiledata", {
                    type: "vector",
                    tiles: [
                        "https://gcp-asia-northeast1.api.carto.com/v3/maps/carto_dw/tileset/{z}/{x}/{y}?name=carto-dw-ac-moe5kln.shared.ue_lc_kotoku2_tileset3&partition=0_20_931416_931626_412810_413121_3999_1&formatTiles=mvt&cache=1707283207517&v=3.0&access_token=eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfbW9lNWtsbiIsImp0aSI6IjliZGQyNGFiIn0.U7LQBvOOVMeCOcm2Ubu3at0BVrg3yCR3yMt0Govt0uc",
                    ],
                });

                bufferMap.addLayer({
                    id: "tiledata",
                    type: "fill", // Changed from 'line' to 'fill' for area features
                    source: "tiledata",
                    "source-layer": "default",
                    paint: {
                        "fill-color": [
                            "match",
                            ["get", "type"], // Get the 'type' property from the feature
                            "tree",
                            "darkgreen", // Green color for trees
                            "grass",
                            "green", // Light green for grass
                            "bush",
                            "red", // Yellow for bushes
                            "water",
                            "blue", // Blue for water
                            "pink", // Default color if none of the above types match
                        ],
                        "fill-opacity": 0.2,
                    },
                });



                bufferMap.on("styledata", () => {

                    // Further debugging to check if layers are rendered
                    const layers = bufferMap.getStyle().layers;
                    if (layers.find((layer) => layer.id === "tiledata")) {

                        resolve();
                    } else {
                        console.error("Tile layer not found in style configuration");
                        reject("Tile layer configuration failed");
                    }
                });
            } catch (error) {
                console.error("Error adding tile layer:", error);
                reject(error);
            }
        });

        bufferMap.on("error", function (e) {
            console.error("Critical map error:", e.error);
            reject(e.error);
        });
    });
};

export default processBufferArray;