import React from "react";
import { useSelector } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

const ChartsPage = ({langData}) => {
  // Use useSelector to access sum values from the Redux store
  const aoisum = useSelector(state => state.polydata.aoisum);
  const plan1sum = useSelector(state => state.polydata.plan1sum);
  const plan2sum = useSelector(state => state.polydata.plan2sum);
  const plan3sum = useSelector(state => state.polydata.plan3sum);

  const getChartData = () => [
    { name: langData.aoi, value: aoisum, fill: '#8884d8' },
    { name: langData.plan1, value: plan1sum, fill: '#82ca9d' },
    { name: langData.plan2, value: plan2sum, fill: '#ffc658' },
    { name: langData.plan3, value: plan3sum, fill: '#ff7300' },
  ];

  const handleBackButtonClick = () => {
    window.history.back(); // Simulate a browser back button click
  };

  const handleExportButtonClick = () => {
    // Implement export functionality
    // console.log('Export button clicked');
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ background: 'rgba(255, 255, 255, 0.8)', border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}>
          <p style={{ margin: '0' }}>SUM: {payload[0].value}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
        <IconButton onClick={handleBackButtonClick} color="primary" size="small">
          <ArrowBackIcon />
        </IconButton>
        <Button variant="contained" color="primary" onClick={handleExportButtonClick} style={{ marginLeft: '10px' }}>
          {langData.exportReport}
        </Button>
      </div>
      <div style={{ background: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '80%', margin: 'auto' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>{langData.sumChart}</h2>
        {/* Center the BarChart and adjust its width */}
        <BarChart width={800} height={400} layout="vertical" data={getChartData()} style={{ margin: 'auto' }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="value"  fill={(entry) => entry.fill} />
        </BarChart>
      </div>
    </div>
  );
};

export default ChartsPage;
