/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
  Link,
  ListItem,
} from "@mui/material";
// import AWS from "aws-sdk";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import LanguageIcon from '@mui/icons-material/Language';
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import BasemapSelector from "../common/BaseMapSelector";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBasemap } from "../../redux/slices/basemapSlice";
import FileSelectDialog from "../Project/FileSelectDialog";
// import { decodeJWT } from "aws-amplify/auth";
// import jwtDecode from "jwt-decode";
import { getCognitoUser } from "../../utils/carto";
import { Link as RouterLink } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";

const Navbar = ({ isAdmin, langData ,currentLanguage }) => {
  // console.log('isAdmin', isAdmin)
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  // console.log('user', user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedBasemap = useSelector((state) => state.basemap);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loggedInUsername, setloggedInUsername] = useState();

  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const handleReload = () => {
    window.location.reload();
  };
  // const decodedToken = jwtDecode(token);
  // console.log('decoded', decodedToken);

  const handleBasemapChange = (event) => {
    const newBasemap = event.target.value;
    dispatch(setSelectedBasemap(newBasemap));
  };
  const fetchCurrentUser = async () => {
    // console.log("fetching user from cognito");
    try {
      const data = await getCognitoUser(user.username);
      setloggedInUsername(data);
    } catch (error) {
      console.error("Error fetching Cognito user:", error);
    }
  };

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route) => {
    navigate(route);
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(); // Clears AWS Amplify session
      navigate("/"); // Redirect to the login/home page
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const preferredUsernameObj = loggedInUsername?.UserAttributes?.find(
    (attr) => attr.Name === "preferred_username"
  );

  // Extract the Value from the preferredUsernameObj
  const preferredUsername = preferredUsernameObj
    ? preferredUsernameObj.Value
    : null;
  const username = preferredUsername;

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link component={RouterLink} to="/" color="inherit" underline="none">
            {langData.uenet}
          </Link>
        </Typography>
        <Box sx={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <BasemapSelector
            selectedBasemap={selectedBasemap}
            onChange={handleBasemapChange}
            langData={langData}
          />
        </Box>
        <IconButton onClick={handleSettingsClick} color="inherit">
          <SettingsIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "10px",
              marginTop: "8px",
              minWidth: "200px", // Set minimum width for the menu
            },
          }}
        >
          <List>
            {/* Username section */}
            <ListItemButton enabled="true">
              <Avatar sx={{ bgcolor: "#3f51b5", marginRight: "10px" }}>
                {username && username.slice(0, 1).toUpperCase()}
              </Avatar>
              <ListItemText primary={username} />
            </ListItemButton>
            <Divider />

            {/* Navigation section */}
            <ListItemButton onClick={() => handleMenuItemClick("/")}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={langData.home} />
            </ListItemButton>
            {isAdmin && (
              <ListItemButton onClick={() => handleMenuItemClick("/dashboard")}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={langData.dashboard} />
              </ListItemButton>
            )}
            {/* <ListItemButton
              onClick={() => handleMenuItemClick("/documentation")}
            >
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={langData.documentation} />
            </ListItemButton> */}
            <Divider />
            {/* language selection section */}
            {/* <ListItem sx={{px:2}}>
              <ListItemIcon>
                <LanguageIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" sx={{ mr: 2 }}>
                    {langData.language}
                    </Typography>
                    <LanguageSwitcher currentLanguage={currentLanguage} />
                  </Box>
                }
              />
            </ListItem> */}

            <Divider />
            {/* Logout section */}
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={langData.logout} />
            </ListItemButton>
          </List>
        </Menu>
      </Toolbar>
      <FileSelectDialog
        langData={langData}
        open={openDialog}
        onClose={handleDialogClose}
      />
    </AppBar>
  );
};

export default Navbar;
