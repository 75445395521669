import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'leaflet/dist/leaflet.css';
import awsconfig from './awsconfig';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { lightTheme } from './theme';
import { SocketProvider } from './Context/SocketContext';


Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={lightTheme}>
    <CssBaseline />
    <SocketProvider>
      <App />
    </SocketProvider>
  </ThemeProvider>
);

reportWebVitals();
