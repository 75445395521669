import mapboxgl from "mapbox-gl";

mapboxgl.accessToken =
    process.env.REACT_APP_MAPBOX_TOKEN;

const calculateRGBColor = (hsi) => {
    let rgbColor;

    if (hsi > 0.95 && hsi <= 1.0) {
        rgbColor = "rgb(55, 97, 20)";
    } else if (hsi > 0.9 && hsi <= 0.95) {
        rgbColor = "rgb(83, 124, 29)";
    } else if (hsi > 0.85 && hsi <= 0.9) {
        rgbColor = "rgb(116, 152, 36)";
    } else if (hsi > 0.8 && hsi <= 0.85) {
        rgbColor = "rgb(157, 185, 44)";
    } else if (hsi > 0.75 && hsi <= 0.8) {
        rgbColor = "rgb(202, 218, 52)";
    } else if (hsi > 0.7 && hsi <= 0.75) {
        rgbColor = "rgb(255, 255, 59)";
    } else if (hsi > 0.65 && hsi <= 0.7) {
        rgbColor = "rgb(245, 216, 50)";
    } else if (hsi > 0.6 && hsi <= 0.65) {
        rgbColor = "rgb(235, 172, 41)";
    } else if (hsi > 0.55 && hsi <= 0.6) {
        rgbColor = "rgb(228, 131, 30)";
    } else if (hsi > 0.5 && hsi <= 0.55) {
        rgbColor = "rgb(223, 90, 16)";
    } else if (hsi >= 0 && hsi <= 0.5) {
        rgbColor = "rgb(219, 38, 4)";
    }

    return rgbColor;
};

const getTimestampedFilename = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `maps_${year}${month}${day}_${hours}${minutes}${seconds}.pdf`;
};

const handleMapMove = (
    mapRef,
    newCenter,
    newZoom,
    movement,
    mapAoiRef,
    mapPlan1Ref,
    mapPlan2Ref,
    mapPlan3Ref
) => {
    const duration = 0;
    if (!movement && mapRef) {
        const targetRefs = [mapAoiRef, mapPlan1Ref, mapPlan2Ref, mapPlan3Ref];
        const currentIndex = targetRefs.findIndex((ref) => ref === mapRef);
        if (currentIndex !== -1) {
            movement = true;

            for (let i = 0; i < targetRefs.length; i++) {
                if (i !== currentIndex) {
                    if (targetRefs[i].current) {
                        targetRefs[i].current.easeTo({
                            center: newCenter,
                            zoom: newZoom,
                            duration,
                        });
                    }
                }
            }

            movement = false;
        }
    }
};

const addPopupToMap = (map, layerId, popupContent, popupRef) => {
    map.on("mousemove", layerId, (e) => {
        map.getCanvas().style.cursor = "pointer";
        popupRef.current.setLngLat(e.lngLat).setHTML(popupContent).addTo(map);
    });

    map.on("mouseleave", layerId, () => {
        map.getCanvas().style.cursor = "";
        popupRef.current.remove();
    });
};

const createMap = (
    containerId,
    initialCenter,
    initialZoom,
    mapRef,
    selectedBasemap,
    movement,
    mapAoiRef,
    mapPlan1Ref,
    mapPlan2Ref,
    mapPlan3Ref
) => {
    if (containerId) {
        const map = new mapboxgl.Map({
            container: containerId,
            style: `${selectedBasemap}`,
            center: initialCenter,
            zoom: initialZoom,
            pitch: 45,
            bearing: 0,
            preserveDrawingBuffer: true
        });

        map.on('load', () => {
            const canvasUrl = map.getCanvas().toDataURL();
            // console.log('Buffer map canvas URL:', canvasUrl);
        });

        mapRef.current = map;

        const navControl = new mapboxgl.NavigationControl();
        mapRef.current.addControl(navControl, "bottom-right");

        map.on("move", () => {
            const newCenter = map.getCenter().toArray();
            const newZoom = map.getZoom();
            handleMapMove(
                mapRef,
                newCenter,
                newZoom,
                movement,
                mapAoiRef,
                mapPlan1Ref,
                mapPlan2Ref,
                mapPlan3Ref
            );
        });

        return map;
    }
};

const addBufferPolygonToMap = (map, polygonCoords) => {
    if (polygonCoords) {
        const bufferGeoJson = {
            type: "Feature",
            geometry: {
                type: "LineString", // Change to LineString for a line representation
                coordinates: polygonCoords[0], // Use the first set of coordinates from the polygon
            },
            properties: {
                description: "Buffer Polygon",
            },
        };
        map.addLayer({
            id: `buffer-layer-${polygonCoords[0]}-${polygonCoords[1]}`, // Unique layer ID for each buffer polygon
            type: "line", // Change type to "line" for a line representation
            source: {
                type: "geojson",
                data: bufferGeoJson,
            },
            paint: {
                "line-color": "#000", // Red color for the line
                "line-width": 3, // Adjust line width as needed
            },
        });
    }
};


const addPolygonsToMap = (map, data, circleCoords, aoi) => {
    const addAllPolygons = () => {
        data.forEach((polygon, i) => {
            const { coordinates, stats } = polygon;

            if (stats) {
                if (stats.type === "Polygon") {
                    addPolygonToMap(map, stats, coordinates[0]);
                } else if (stats.type === "MultiPolygon") {
                    addMultipolygonToMap(map, stats, coordinates);
                }
            }
        });

        // Add circles
        addBufferPolygonToMap(map, circleCoords);
        // Add AOI polygon
        addAoiPolygonToMap(map, aoi);
    };

    if (map.isStyleLoaded()) {
        addAllPolygons();
    } else {
        map.on("load", addAllPolygons);
    }
};

const addAoiPolygonToMap = (map, polygonGeoJson) => {
    if (polygonGeoJson) {

        map.addLayer({
            id: `polygon-outline-layer-${polygonGeoJson.coordinates[0][0][0]}-${polygonGeoJson.coordinates[0][0][1]}`, // Unique layer ID for each polygon outline
            type: "line",
            source: {
                type: "geojson",
                data: polygonGeoJson,
            },
            paint: {
                "line-color": "#000", // Red color outline
                "line-width": 2, // Adjust line width as needed
            },
        });
    } else {

    }
};

const addPolygonToMap = (map, stats, coordinates) => {
    const transformedCoordinates = coordinates.map(([lng, lat]) => [lng, lat]);

    const rgbColor = calculateRGBColor(stats.hsi);

    map.addSource(`polygon-source-${stats.id}`, {
        type: "geojson",
        data: {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [transformedCoordinates],
            },
            properties: stats,
        },
    });

    map.addLayer({
        id: `polygon-layer-${stats.id}`,
        type: "fill",
        source: `polygon-source-${stats.id}`,
        paint: {
            "fill-color": rgbColor,
            "fill-opacity": 0.2,
            "fill-outline-color": "transparent",
            "fill-antialias": true,
            "fill-translate": [0, 0],
            "fill-translate-anchor": "viewport",
        },
    });

    const bounds = new mapboxgl.LngLatBounds();
    transformedCoordinates.forEach((lngLat) => {
        bounds.extend(lngLat);
    });

    const padding = 20;

    map.fitBounds(bounds, {
        padding,
        zoom: 12,
    });
};

const addMultipolygonToMap = (map, stats, coordinates) => {
    const transformedCoordinates = coordinates.map((polygon) =>
        polygon.map((ring) => ring.map(([lng, lat]) => [lng, lat]))
    );

    const rgbColor = calculateRGBColor(stats.hsi);

    map.addSource(`multipolygon-source-${stats.id}`, {
        type: "geojson",
        data: {
            type: "Feature",
            geometry: {
                type: "MultiPolygon",
                coordinates: transformedCoordinates,
            },
            properties: stats,
        },
    });

    map.addLayer({
        id: `multipolygon-layer-${stats.id}`,
        type: "fill",
        source: `multipolygon-source-${stats.id}`,
        paint: {
            "fill-color": rgbColor,
            "fill-opacity": 0.2,
            "fill-outline-color": "transparent",
            "fill-antialias": true,
            "fill-translate": [0, 0],
            "fill-translate-anchor": "viewport",
        },
    });

    const bounds = new mapboxgl.LngLatBounds();
    transformedCoordinates.forEach((polygon) => {
        polygon.forEach((ring) => {
            ring.forEach((lngLat) => {
                bounds.extend(lngLat);
            });
        });
    });

    const padding = 20;

    map.fitBounds(bounds, {
        padding,
        zoom: 12,
    });
};

function getCenterOfPolygon(coords) {
    
    if (!Array.isArray(coords) || coords.length === 0) {
        console.error("Invalid coordinates passed to getCenterOfPolygon:", coords);
        return null;
    }

    let minLat = coords[0][1],
        maxLat = coords[0][1];
    let minLng = coords[0][0],
        maxLng = coords[0][0];

    coords.forEach((coord) => {
        if (coord[1] < minLat) minLat = coord[1];
        if (coord[1] > maxLat) maxLat = coord[1];
        if (coord[0] < minLng) minLng = coord[0];
        if (coord[0] > maxLng) maxLng = coord[0];
    });

    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;

    return [centerLng, centerLat];
}

const addPolygonOutlineToMap = (map, polygonGeoJson) => {
    map.on("load", () => {
        map.addLayer({
            id: `polygon-outline-layer-${polygonGeoJson.coordinates[0][0][0]}-${polygonGeoJson.coordinates[0][0][1]}`, // Unique layer ID for each polygon outline
            type: "line",
            source: {
                type: "geojson",
                data: polygonGeoJson,
            },
            paint: {
                "line-color": "#000", // Red color outline
                "line-width": 2, // Adjust line width as needed

            },
        });
    });
};


const addPageToPdf = async (map, plan, pdfContent, template) => {
    const page = document.createElement("div");
    pdfContent.appendChild(page);

    await new Promise((resolve) => {
        map.on("load", () => {
            addPolygonsToMap(map, plan.polygonsData);
        });

        map.once("idle", () => {
            const canvas = document.createElement("canvas");
            canvas.width = map.getCanvas().width;
            canvas.height = map.getCanvas().height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(map.getCanvas(), 0, 0, canvas.width, canvas.height);

            const canvasUrl = canvas.toDataURL("image/jpeg");
            console.log('PDF map canvas URL:', canvasUrl);

            // Replace placeholders in the template
            const pageHtml = template
                .replace("{title}", plan.name)
                .replace(
                    "{content}",
                    `<img src="${canvas.toDataURL(
                        "image/jpeg"
                    )}" style="width: 80%; height: auto; margin: 0 auto; display: block;" />`
                );

            // Add header and descriptions to the first page only
            if (!pdfContent.querySelector(".header")) {
                const headerHtml = `
          <div class="header">
            <h1 style="text-align: center;">Project Name: Name</h1>
            <hr />
            <p>Description: Your description here</p>
            <p>Model Used: Your model name here</p>
            <p>Plan 1 Description: Your plan 1 description here</p>
            <p>Plan 2 Description: Your plan 2 description here</p>
            <p>Plan 3 Description: Your plan 3 description here</p>
          </div>
          <div style="position: absolute; top: 20px; right: 20px;">${getCurrentDate()}</div>
          <hr />
        `;
                page.innerHTML = headerHtml + pageHtml;
            } else {
                page.innerHTML = pageHtml;
            }

            // Cleanup: Remove the map instance after processing
            map.remove();

            resolve();
        });
    });
};

// Helper function to get current date in the desired format
const getCurrentDate = () => {
    const now = new Date();
    return `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
};

function getFillColor(type) {
    switch (type) {
        case "grass":
            return "green";
        case "tree":
            return "darkgreen";
        case "water":
            return "blue";
        case "bush":
            return "red";
        default:
            return "pink";
    }
}


export {
    calculateRGBColor,
    addBufferPolygonToMap,
    addPolygonsToMap,
    addPageToPdf,
    addPolygonOutlineToMap,
    handleMapMove,
    createMap,
    getCurrentDate,
    getTimestampedFilename,
    addPopupToMap,
    getCenterOfPolygon,
    getFillColor
};
