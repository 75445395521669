import React from 'react';
import { Image, Page, Text, View } from '@react-pdf/renderer';
import styles from './style';
import Header from './Header';
import { getCurrentDate } from '../../utils/functions';
import mapLegendImg from "../../resources/maplegend.png";
import Legend from './Legend';

const ImagePage = ({ projectName, images }) => {
    const currentDate = getCurrentDate();

    // Validate required data
    if (!images || !images.bufferImages || !images.mapImages) {
        console.warn('Missing required image data');
        return null;
    }

    // Utility function to group images in pairs
    const groupInPairs = (array) => {
        const pairs = [];
        for (let i = 0; i < array.length; i += 2) {
            pairs.push(array.slice(i, i + 2));
        }
        return pairs;
    };

    const bufferPairs = groupInPairs(images.bufferImages);
    const mapPairs = groupInPairs(images.mapImages);

    return (
        <>
            {/* Buffer Maps Pages */}
            {bufferPairs.map((pair, pageIndex) => (
                <Page key={`buffer-${pageIndex}`} size="A4" style={styles.page}>
                    <Header projectName={projectName} currentDate={currentDate} />
                    <View style={styles.content}>
                        <View style={styles.imageContainer}>
                            {pair.map((img, index) => (
                                <View key={`buffer-image-${pageIndex}-${index}`} style={styles.imagePair}>
                                    <Legend />
                                    {img.dataUrl && (
                                        <Image
                                            src={img.dataUrl}
                                            style={styles.mapImage}
                                        />
                                    )}
                                </View>
                            ))}
                        </View>
                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )}
                        fixed
                    />
                </Page>
            ))}

            {/* Plan Maps Pages */}
            {mapPairs.map((pair, pageIndex) => (
                <Page key={`plan-${pageIndex}`} size="A4" style={styles.page}>
                    <Header projectName={projectName} currentDate={currentDate} />
                    <View style={styles.content}>
                        <View style={styles.imageContainer}>
                            {pair.map((img, index) => (
                                <View key={`plan-image-${pageIndex}-${index}`} style={styles.imagePair}>
                                    <Image 
                                        src={mapLegendImg}
                                        style={styles.legend}
                                    />
                                    {img && (
                                        <Image
                                            src={img}
                                            style={styles.mapImage}
                                        />
                                    )}
                                </View>
                            ))}
                        </View>

                        {/* Add chart below the last map */}
                        {pageIndex === mapPairs.length - 1 && images.chartImage && (
                            <View style={styles.chartContainer}>
                                <Image 
                                    src={images.chartImage}
                                    style={styles.chartImage}
                                />
                            </View>
                        )}
                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )}
                        fixed
                    />
                </Page>
            ))}
        </>
    );
};

export default ImagePage;
