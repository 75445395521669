/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import { Snackbar, SnackbarContent } from "@mui/material";
import mapboxgl from "mapbox-gl";
import PolygonDialog from "../common/PolygonColorDialog";
import NonSelectableLayer from "../../utils/customclass";
import { useSelector, useDispatch } from "react-redux";
import { PropagateLoader } from "react-spinners";
import CircularProgress from "@mui/material/CircularProgress";
import { setDataChanged } from "../../redux/slices/dataChangeSlice";
import { getFillColor } from "../../utils/functions";
import { createTileLayer } from "../../utils/createTileLayer";
import { clearUploadedPolygon } from "../../redux/slices/polygonSlice";
import {
 
  savePlanPolygonData,
  updatePlan,
  deletePlanPolygonData,
  fetchTileLayer,
} from "../../utils/carto";

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_TOKEN;

const PlanMapComponent = ({ selectedTab, project, planData, langData }) => {
  const geotiffData = useSelector((state) => state.polydata.geotiffData);
  let tiffData;
  if (geotiffData && selectedTab) {
    tiffData = geotiffData[selectedTab];
  }

  // console.log('tiff', tiffData)

  const dispatch = useDispatch();

  const mapContainerRef = useRef(null);

  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const uploadedPolygon = useSelector((state) => state.polygon.uploadedPolygon);
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [map, setMap] = useState(null);
  const [overlay, setOverlay] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [render, setRender] = useState(false);
  const mapRef = useRef(null);
  const [selectedPolygon, setSelectedPolygon] = useState(null);

  const selectedBasemap = useSelector((state) => state.basemap.selectedBasemap) || 'mapbox://styles/mapbox/streets-v11';
  useEffect(() => {
    try {
      const center = [35.68334155120243, 139.7670395118941];
      const map = L.map(mapContainerRef.current).setView(center, 7);
        mapRef.current = map;

      //function from utils for map config
      const tileLayer = createTileLayer(L, mapboxgl.accessToken, selectedBasemap);
    
    // Add the tile layer to the map
      tileLayer.addTo(map);
      
      setMap(map);

      const handleResize = () => {
       
        if (map) {
          map.invalidateSize();
        }
      };

      const observer = new ResizeObserver(handleResize);
      observer.observe(mapContainerRef.current);

      const addPolygonsToMap = (polygonData) => {
        if (polygonData && polygonData.rows) {
          polygonData.rows.forEach((row) => {
            const coordinates = row.geom.coordinates[0];
            const latLngs = coordinates.map(([lng, lat]) => [lat, lng]);
            let fillColor = "#FF00FF"; // Default pink color

            // Set the fillColor based on the type property
            switch (row.type) {
              case "treecover":
                fillColor = "#00FF00"; // Green
                break;
              case "openwater":
                fillColor = "#0000FF"; // Blue
                break;
              case "grassland":
                fillColor = "#FF0000"; // Red
                break;
              default:
                break;
            }

            const polygonStyle = {
              fillColor,
              color: fillColor,
              weight: 2,
            };

            const planPolygon = L.polygon(latLngs, polygonStyle).addTo(map);
            drawnItems.addLayer(planPolygon);

            planPolygon.project_id = row.project_id;
            planPolygon.plan_id = row.plan_id;
            planPolygon.id = row.id;
            planPolygon.type = row.type;
            planPolygon.landcover_type = row.landcover_type;
            planPolygon.crown_width = row.crown_width;

            planPolygon.on("click", () => {
              setSelectedPolygon(planPolygon);
            });
          });
        }
      };

      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);

      const drawControl = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems,
          edit: true,
          remove: true,
        },
        draw: {
          polygon: true,
          polyline: false,
          rectangle: false,
          circlemarker: false,
          circle: false,
          marker: false,
        },
      });
      if (project && project.geom) {
        map.addControl(drawControl);
      }

      if (project.geom) {
        // Check if project.geom is a string before processing it
        if (typeof project.geom === "string") {
          const coordinates = project.geom
            .replace("POLYGON((", "")
            .replace("))", "")
            .split(",")
            .map((pair) => {
              const [lng, lat] = pair.trim().split(" ").map(parseFloat);
              return [lat, lng];
            });

          if (coordinates.length > 0) {
            const polygonStyle = {
              fillColor: "#FF00FF", // Pink fill color
              color: "#FF00FF", // Pink outline color
              weight: 2, // Outline width
            };

            const aoiPolygon = new NonSelectableLayer(
              coordinates,
              polygonStyle
            );

            aoiPolygon.addTo(map);
            map.fitBounds(aoiPolygon.polygon.getBounds(), {
              padding: [20, 20],
            });
            const currentZoom = map.getZoom();
            const newZoom = currentZoom - 1;
            map.setZoom(newZoom);
          }
        } else if (project.geom) {
          const coordinates = project.geom.coordinates[0].map(
            ([lng, lat]) => [lat, lng]
          );

          if (coordinates.length > 0) {
            const polygonStyle = {
              fillColor: "#FF00FF", // Pink fill color
              color: "#FF00FF", // Pink outline color
              weight: 2, // Outline width
            };
            const aoiPolygon = new NonSelectableLayer(
              coordinates,
              polygonStyle
            );

            aoiPolygon.addTo(map);
            map.fitBounds(aoiPolygon.polygon.getBounds(), {
              padding: [20, 20],
            });
            const currentZoom = map.getZoom();
            const newZoom = currentZoom - 1;
            map.setZoom(newZoom);
          } else {
            console.error(
              "No valid coordinates found in project.geom:",
              project.geom
            );
          }
        } else {
          console.error("Invalid project.geom:", project.geom);
        }
      }

      map.on("draw:created", async (e) => {
        const layer = e.layer;
        const isUploaded = e.isUploaded || false; // Check if the polygon is uploaded
        drawnItems.addLayer(layer);
      
        if (!isUploaded) {
          // Manually drawn polygon - Show dialog
          setSelectedPolygon(layer);
          setIsDialogOpen(true);
        } else {
          // Uploaded polygon - Use `landcover_type` from properties or default to "treecover"
          const geoJSON = layer.toGeoJSON();
          const landcoverType = geoJSON.properties?.landcover_type || "treecover";
      
          // Set style based on `landcover_type`
          const fillColor =
            landcoverType === "treecover"
              ? "#00FF00" // Green
              : landcoverType === "openwater"
              ? "#0000FF" // Blue
              : landcoverType === "grassland"
              ? "#FF0000" // Red
              : "#FF00FF"; // Default color (pink)
      
          layer.setStyle({
            fillColor,
            color: fillColor,
            weight: 2,
          });
      
          // Show loader
          setIsLoading(true);
      
          try {
            // Save polygon data
            const polygonData = {
              project_id: project ? project.project_id : null,
              plan_id: selectedTab,
              geom: geoJSON.geometry,
              type: landcoverType,
              landcover_type: landcoverType,
              crown_width: null,
            };
   
            const response = await savePlanPolygonData(polygonData);
            if (response.data) {
              // Update layer properties with saved data
              layer.plan_id = response.data.plan_id;
              layer.id = response.data.id;
              layer.project_id = response.data.project_id;
      
              setSuccessSnackbar(true);
              setSnackbarMessage(`${langData.uploadedPolygonMessage} '${landcoverType}'.`);
              dispatch(setDataChanged(true));
            }
          } catch (error) {
            console.error("Error saving uploaded polygon:", error);
            setSnackbarMessage(langData.polygonUploadFailed);
            setSuccessSnackbar(true);
          } finally {
            // Hide loader
            setIsLoading(false);
          }
        }
      });
      
      
      
      
      
      

      map.on("draw:edited", async (e) => {
        const layers = e.layers;
        setIsLoading(true); // Show loader while making the request

        for (const layer of layers.getLayers()) {
          const polygonData = {
            geom: layer.toGeoJSON().geometry,
          };
          try {
            const response = await updatePlan(layer, polygonData);

            if (response.status === 200) {
              // Request successful, show success snackbar
              setSuccessSnackbar(true);
              dispatch(setDataChanged(true));
              setSnackbarMessage(langData.updateSuccess);
            } else {
              console.error("Error updating polygon:", response.statusText);
            }
          } catch (error) {
            console.error("Error updating polygon:", error);
          }
        }

        setIsLoading(false); // Hide loader when the request is completed
      });
      map.on("draw:deleted", async (e) => {
        const layers = e.layers;
        setIsLoading(true); // Show loader while making the request

        for (const layer of layers.getLayers()) {
          try {
            await deletePlanPolygonData(layer);
            dispatch(setDataChanged(true));
          } catch (error) {
            console.error("Error deleting polygon:", error);
          }
        }

        setIsLoading(false); // Hide loader when the request is completed
        setSuccessSnackbar(true);
        setSnackbarMessage(langData.deleteSucccess);
      });
      addPolygonsToMap(planData[selectedTab]);

      if (project) {
        if (project) {
          fetchTileLayer("ue_lc_kotoku2_tileset3")
            .then((data) => {
              if (data) {
                L.vectorGrid
                  .protobuf(data.data.url, {
                    rendererFactory: L.canvas.tile,
                    vectorTileLayerStyles: {
                      default: function (properties, zoom) {
                       
                        let fillColor = getFillColor(properties.type);
        
                        return {
                          weight: 0,
                          fillOpacity: 1,
                          fill: true,
                          fillColor: fillColor,
                        
                        };
                      },
                    },
                  })
                  .addTo(map);
              } else {
                console.error("Invalid data structure received.");
              }
            })
            .catch((error) => {
              // console.log("error", error);
            });
        }
        
      }

      setIsLoading(false);
      setRender(false);

      return () => {
        observer.disconnect();
        map.remove();
      };
    } catch (error) {
      // console.log("error", error);
    }
  }, [render]);

 // Depend on selectedBasemap and map

 useEffect(() => {
  if (!mapRef.current || !selectedBasemap) return; // Do nothing if map or basemap isn't defined

  // Remove the old tile layer if it exists
  // if (tileLayer) {
  //   mapRef.current.removeLayer(tileLayer);
  // }

  // Create and add the new tile layer
  const newTileLayer = createTileLayer(L, mapboxgl.accessToken, selectedBasemap);
  newTileLayer.addTo(mapRef.current);
  // setTileLayer(newTileLayer);
}, [selectedBasemap]); // This effect runs whenever the selectedBasemap chang
  

  useEffect(() => {
    if (map && uploadedPolygon) {
      // console.log('triggered')
      const geoJSONLayer = L.geoJSON(uploadedPolygon);
      geoJSONLayer.eachLayer((layer) => {
        if (layer instanceof L.Polygon) {
          // Programmatically fire the `draw:created` event with a custom property
          map.fire("draw:created", {
            layer: layer,
            layerType: "polygon",
            isUploaded: true, // Add a custom flag
          });
  
          // Clear uploaded polygon from Redux after processing
          
          dispatch(clearUploadedPolygon());
        }
      });
    }
  }, [uploadedPolygon, map, dispatch]);
  
  
  

  useEffect(() => {
    if (tiffData && tiffData.length > 0) {
      // console.log("tiff meta", tiffData);
      const imageUrl = tiffData[0].tiffurl;
      const boundingBox = tiffData[0].bbox.split(",").map(Number); // Split and convert to numbers
      const imageBounds = [
        [boundingBox[1], boundingBox[0]], // Southwest coordinates
        [boundingBox[3], boundingBox[2]], // Northeast coordinates
      ];

      if (map) {
        // Check if overlay exists, if yes, remove it
        if (overlay) {
          map.removeLayer(overlay);
        }

        const imageOverlayInstance = L.imageOverlay(
          imageUrl,
          imageBounds
        ).addTo(map);
        setOverlay(imageOverlayInstance);
        setMap(map);
      }
    } else {
      // No tiffData or empty array, remove overlay if it exists
      if (overlay) {
        map.removeLayer(overlay);
        setOverlay(null); // Clear the overlay state
      }
    }
  }, [tiffData, map]);

  useEffect(() => {
    setShowAlert(true);
    const timeout = setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [selectedTab]);

  const handlePolygonColorChange = async (selectedOption) => {
    setIsDialogOpen(false);
  
    if (selectedPolygon) {
      // Apply selected style
      const fillColor =
        selectedOption === "treecover"
          ? "#00FF00"
          : selectedOption === "openwater"
          ? "#0000FF"
          : "#FF0000";
  
      selectedPolygon.setStyle({
        fillColor,
        color: fillColor,
        weight: 2,
      });
  
      const polygonData = {
        project_id: project ? project.project_id : null,
        plan_id: selectedTab,
        geom: selectedPolygon.toGeoJSON().geometry,
        type: selectedOption,
        landcover_type: selectedOption,
        crown_width: null,
      };
  
      try {
        const response = await savePlanPolygonData(polygonData);
        if (response.data) {
          // Update the polygon's properties with new data from the server
          selectedPolygon.plan_id = response.data.plan_id;
          selectedPolygon.id = response.data.id;
          selectedPolygon.project_id = response.data.project_id;
  
          setSuccessSnackbar(true);
          setSnackbarMessage(langData.saveSuccess);
          dispatch(setDataChanged(true));
        }
      } catch (error) {
        console.error("Error saving polygon data:", error);
      }
    }
  };
  


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // height: "calc(100vh - 5vh)",
        height: "100%",
      }}
    >
      {/* Snackbar for success message */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successSnackbar}
        autoHideDuration={3000}
        onClose={() => setSuccessSnackbar(false)}
      >
        <SnackbarContent
          message={snackbarMessage}
          style={{ backgroundColor: "#007BFF" }}
        />
      </Snackbar>

      <div style={{ display: "flex", flexGrow: 1, position: "relative" }}>
        {/* Loader in the center of the screen */}
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <CircularProgress/>
            <div style={{ marginTop: 16, color: "white" }}>{langData.inProgress}...</div>
          </div>
        )}

        <div
          ref={mapContainerRef}
          style={{ flex: "1", position: "relative", overflow: "hidden" }}
        ></div>
      </div>
      <PolygonDialog
        langData={langData}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onFormSubmit={handlePolygonColorChange}
      />
    </div>
  );
};

export default PlanMapComponent;
