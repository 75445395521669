/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { getResult } from "../../utils/carto";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MapLegend from "./MapLegend";
import ChartsDialog from "./ChartsDialog";
import DownloadIcon from "@mui/icons-material/Download";
import Menu from "@mui/material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import PlanDescription from "./PlanDescription";
import Tooltip from "@mui/material/Tooltip";

// Helper to format a value: if it's null, undefined, or exactly 0, return an empty string.
const formatVal = (val) => {
  return val === 0 || val === null || val === undefined ? "" : val;
};

const Section = ({ title, statistics, langData }) => (
  <div style={{ margin: "1px 0", textAlign: "center" }}>
    <h5 style={{ margin: "0" }}>{title}</h5>
    <p style={{ margin: "0", whiteSpace: "nowrap", textAlign: "left" }}>
      <strong>{langData.aoi}:</strong> {formatVal(statistics.sum)}
      <br />
      <strong>{langData.plan1}:</strong> {formatVal(statistics.plan1sum)}
      <br />
      <strong>{langData.plan2}:</strong> {formatVal(statistics.plan2sum)}
      <br />
      <strong>{langData.plan3}:</strong> {formatVal(statistics.plan3sum)}
    </p>
  </div>
);

const Stats = ({
  projectId,
  onBackButtonClick,
  modelString,
  modelArray,
  name,
  jsonData,
  download,
  langData,
  plansDescription,
  onModelSelectionChange,
  pdfLoading,
}) => {
  const [selectedModel, setSelectedModel] = useState("");
  const [filteredModelOptions, setFilteredModelOptions] = useState([]);
  const [selectedModelKey, setSelectedModelKey] = useState("");
  // Initially, we don't set a default selectedId until we process models
  const [selectedId, setSelectedId] = useState(null);
  const navigate = useNavigate();
  const [sumsData, setSumsData] = useState({});
  const [isChartsDialogOpen, setIsChartsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedPlan, setExpandedPlan] = useState("");

  // Fetch plan data when selectedId changes
  useEffect(() => {
    if (selectedId != null) {
      getResult(projectId, selectedId)
        .then((response) => {
          // Accumulate sum values for each plan id
          const sums = response?.data?.reduce((acc, item) => {
            const { plan_id, sum } = item;
            acc[`${plan_id}sum`] = (acc[`${plan_id}sum`] || 0) + sum;
            return acc;
          }, {});
          setSumsData(sums);
        })
        .catch((error) => {
          console.error("Error fetching data for projectId:", projectId, error);
        });
    }
  }, [projectId, selectedId]);

  // Process models on load and set the default selected model and id
  useEffect(() => {
    if (!modelString || !modelArray) {
      return;
    }

    try {
      const parsedModelString = JSON.parse(modelString);
      const modelIds = Object.values(parsedModelString).flat();

      const filterUniqueModels = (models) => {
        const uniqueModels = [];
        const seenIds = new Set();
        for (const model of models) {
          if (!seenIds.has(model.model_id)) {
            seenIds.add(model.model_id);
            uniqueModels.push(model);
          }
        }
        return uniqueModels;
      };

      const filteredOptions = filterUniqueModels(
        modelArray.filter((model) => modelIds.includes(model.model_id))
      );
      setFilteredModelOptions(filteredOptions);

      if (filteredOptions.length > 0) {
        // Set the default model (the first option)
        const defaultModel = filteredOptions[0];
        setSelectedModel(defaultModel.name);
        setSelectedModelKey(defaultModel.name);

        // Map specific model names to desired ids
        const modelNameToId = {
          "Model A": 1,
          "Model B": 2,
          "Model C": 3,
        };
        // Use mapped id if available, otherwise use the model's own model_id
        const defaultId = modelNameToId[defaultModel.name] || defaultModel.model_id;
        setSelectedId(defaultId);
        onModelSelectionChange(defaultId);
      }
    } catch (error) {
      console.error("Error parsing modelString:", error);
    }
  }, [modelString, modelArray, onModelSelectionChange]);

  const handleChartClick = () => {
    setIsChartsDialogOpen(true);
  };

  // When a user manually selects a model from the dropdown:
  const handleModelChange = (event) => {
    const { value } = event.target;
    setSelectedModel(value);
    setSelectedModelKey(value);

    const modelNameToId = {
      "Model A": 1,
      "Model B": 2,
      "Model C": 3,
    };
    const selectedModelId =
      modelNameToId[value] ||
      filteredModelOptions.find((model) => model.name === value)?.model_id;
    setSelectedId(selectedModelId);
    onModelSelectionChange(selectedModelId);
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const downloadJson = () => {
    const jsonDataString = JSON.stringify(jsonData, null, 2);
    setLoading(true);
    const blob = new Blob([jsonDataString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    setLoading(false);
    handleCloseMenu();
  };

  const handleDownloadOption = (option) => {
    if (option === "JSON") {
      downloadJson();
    } else if (option === "PDF") {
      download();
      handleCloseMenu();
    }
  };

  const handlePlanExpand = (planTitle) => {
    setExpandedPlan(planTitle);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        gap: "15px",
      }}
    >
      {loading && <CircularProgress />}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <IconButton onClick={onBackButtonClick} color="primary" size="small">
          <ArrowBackIcon />
        </IconButton>
        <Tooltip title={name} arrow>
          <h3
            style={{
              margin: "0",
              marginLeft: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {name}
          </h3>
        </Tooltip>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "15px",
        }}
      >
        <Button
          style={{ marginRight: "10px", fontSize: "12px" }}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadClick}
        >
          {langData.download}
        </Button>
        <Button
          style={{ fontSize: "12px" }}
          onClick={handleChartClick}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<ShowChartIcon />}
        >
          {langData.chart}
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
          <MenuItem onClick={() => handleDownloadOption("JSON")}>
            {langData.JSON}
          </MenuItem>
          <MenuItem onClick={() => handleDownloadOption("PDF")}>
            {langData.PDF}
          </MenuItem>
        </Menu>
      </div>

      <FormControl style={{ width: "100%", marginRight: "20px" }}>
        <InputLabel id="model-select-label" style={{ color: "#3f51b5", fontSize: "14px" }}>
          {langData.selectModel}
        </InputLabel>
        <Select
          key={selectedModelKey}
          labelId="model-select-label"
          value={selectedModel}
          onChange={handleModelChange}
          label={langData.selectModel}
          input={<OutlinedInput label={langData.selectModel} />}
          style={{
            backgroundColor: "#fff",
            borderRadius: "4px",
            marginTop: "-5px",
            fontSize: "12px",
          }}
        >
          {filteredModelOptions.map((model) => (
            <MenuItem key={model.model_id} value={model.name}>
              {model.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div>
        <Section
          title={langData.sum}
          statistics={{
            projectId,
            sum: sumsData["0sum"],
            plan1sum: sumsData["1sum"],
            plan2sum: sumsData["2sum"],
            plan3sum: sumsData["3sum"],
          }}
          langData={langData}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column", flex: "1 1 0" }}>
        {/* Non-scrolling section */}
        <div>
          {Object.keys(plansDescription).map((key) => (
            <PlanDescription
              key={key}
              title={`Plan${key}`}
              name={key === "1" ? langData.plan1 : key === "2" ? langData.plan2 : langData.plan3}
              description={plansDescription[key]}
              isExpanded={expandedPlan === `Plan${key}`}
              onExpand={handlePlanExpand}
            />
          ))}
        </div>
        {/* Scrolling section */}
        <div style={{ overflowY: "auto", flex: "1 1 0" }}>
          <MapLegend />
        </div>
      </div>

      {isChartsDialogOpen && (
        <ChartsDialog langData={langData} onClose={() => setIsChartsDialogOpen(false)} />
      )}
    </div>
  );
};

export default Stats;