import { createSlice } from '@reduxjs/toolkit';

const basemapSlice = createSlice({
    name: 'basemap',
    initialState: {
        basemaps: [], // Store all basemaps
        selectedBasemap: 'mapbox://styles/mapbox/streets-v11', // Default to a valid Mapbox style
    },
    reducers: {
        setBasemapsFromDB: (state, action) => {
            state.basemaps = action.payload;
        },
        setSelectedBasemap: (state, action) => {
            state.selectedBasemap = action.payload || 'mapbox://styles/mapbox/streets-v11'; // Fallback to a valid style
        },
        addOrUpdateBasemap: (state, action) => {
            const index = state.basemaps.findIndex(basemap => basemap.id === action.payload.id);
            if (index !== -1) {
                state.basemaps[index] = action.payload; // Update the existing basemap
            } else {
                state.basemaps.push(action.payload); // Add new basemap
            }
        },
    },
});

export const { setBasemapsFromDB, setSelectedBasemap, addOrUpdateBasemap } = basemapSlice.actions;
export default basemapSlice.reducer;
