import React, { useState } from 'react';
import { Paper, Tab, Tabs } from '@mui/material';
import UsersTab from './UsersTab';
import BasemapsTab from './BaseMapsTab';  // Ensure the filename and import name are correct
// import SourcesTab from './SourcesTab'; // Keep this import commented until the Sources tab is needed

const Dashboard = ({ langData }) => {
  const [currentTab, setCurrentTab] = useState(0); // Initialize to 0 to select the first active tab

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div style={{ padding: 16, display: "flex", flexDirection: "column", height: "100%" }}>
      <Paper elevation={2} style={{ marginBottom: 16 }}>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={langData.users} />
          <Tab label={langData.baseMap} />
        </Tabs>
      </Paper>
      {currentTab === 0 && <UsersTab langData={langData} />}
      {currentTab === 1 && <BasemapsTab langData={langData} />}
    </div>
  );
};

export default Dashboard;
