// src/redux/slices/projectsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProjects } from '../../utils/carto';

export const fetchProjectsData = createAsyncThunk(
    'projects/fetchProjects',
    async (_, { rejectWithValue }) => {
        try {
            const projects = await fetchProjects();
            return projects;
        } catch (error) {
            return rejectWithValue('Error fetching projects');
        }
    }
);

const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        projects: [],
        receivedData: null,
    },
    reducers: {
        receiveProjectData: (state, action) => {
            state.receivedData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectsData.fulfilled, (state, action) => {
                state.projects = action.payload;
            })
            .addCase(fetchProjectsData.rejected, (state, action) => {
                console.error(action.payload);
            });
    },
});

export const { receiveProjectData } = projectsSlice.actions;
export default projectsSlice.reducer;
