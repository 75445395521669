export const createTileLayer = (L, accessToken, selectedBasemap) => {
    if (selectedBasemap.startsWith('mapbox://styles/')) {
      const mapboxStyle = selectedBasemap.replace('mapbox://styles/', '');
      const mapboxUrl = `https://api.mapbox.com/styles/v1/${mapboxStyle}/tiles/{z}/{x}/{y}?access_token=${accessToken}`;
      return L.tileLayer(mapboxUrl, {
        maxZoom: 19,
        attribution: '© Mapbox © OpenStreetMap contributors',
      });
    } else {
      return L.tileLayer(selectedBasemap, {
        maxZoom: 19,
        attribution: '© OpenStreetMap contributors',
      });
    }
  };
  