import React from 'react';
import { Typography, Paper } from '@mui/material';

const legendData = [
  { range: '0.95 - 1.00', color: 'rgb(55, 97, 20)' },
  { range: '0.90 - 0.95', color: 'rgb(83, 124, 29)' },
  { range: '0.85 - 0.90', color: 'rgb(116, 152, 36)' },
  { range: '0.80 - 0.85', color: 'rgb(157, 185, 44)' },
  { range: '0.75 - 0.80', color: 'rgb(202, 218, 52)' },
  { range: '0.70 - 0.75', color: 'rgb(255, 255, 59)' },
  { range: '0.65 - 0.70', color: 'rgb(245, 216, 50)' },
  { range: '0.60 - 0.65', color: 'rgb(235, 172, 41)' },
  { range: '0.55 - 0.60', color: 'rgb(228, 131, 30)' },
  { range: '0.50 - 0.55', color: 'rgb(223, 90, 16)' },
  { range: '0.00 - 0.50', color: 'rgb(219, 38, 4)' },
];

const MapLegend = () => {
  return (
    <Paper style={{ maxWidth: '100%', boxShadow: 'none', padding: "10px", marginBottom: '50px' }}>
    
      {legendData.map((item, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <div
            style={{
              backgroundColor: item.color,
              width: '20px',
              height: '20px',
              marginRight: '8px',
              borderRadius: '50%',
            }}
          ></div>
          <Typography>{item.range}</Typography>
        </div>
      ))}
    </Paper>
  );
};

export default MapLegend;
