// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import projectsReducer from './slices/projectsSlice';
import polydataReducer from './slices/polydataSlice';
import basemapReducer from './slices/basemapSlice';
import dataChangedReducer from './slices/dataChangeSlice';
import errorReducer from './slices/errorSlice'
import polygonReducer from "./slices/polygonSlice";

export const store = configureStore({
    reducer: {
        projects: projectsReducer,
        polygon: polygonReducer,
        polydata: polydataReducer,
        basemap: basemapReducer,
        dataChanged: dataChangedReducer,
        error: errorReducer
    },
});
