import React, { createContext, useContext, useState } from 'react';
import { getProject } from '../utils/carto';

const ProjectsContext = createContext();

export const useProjectsContext = () => useContext(ProjectsContext);

export const ProjectsProvider = ({ children }) => {
  const [projectsData, setProjectsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateProjects = async (newProjects) => {
    setProjectsData(newProjects);
  };

  // This function will be used to fetch a single project
  const fetchSingleProject = async (projectId) => {
    try {
      setIsLoading(true);
      const response = await getProject(projectId);
      if (response?.data) {
        setProjectsData(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching project:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ProjectsContext.Provider 
      value={{ 
        projectsData, 
        updateProjects,
        fetchSingleProject,
        isLoading
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsProvider;