/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import downloadImagesToPdf from "../utils/pdf/downloadImage";

import {
    getResult,
    getProject,
    fetchModels,
    getBufferCircle,
    getAoi250Data,
} from "../utils/carto";
import { v4 as uuidv4 } from "uuid";
import { useRef } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
// import MapboxCompare from "mapbox-gl-compare";
import {
    addPolygonOutlineToMap,
    addPolygonsToMap,
    addPopupToMap,
} from "../utils/functions";
import {
    IconLayoutSidebarLeftExpand,
    IconLayoutSidebarRightExpand,
} from "@tabler/icons-react";
import {
    setAOIPolyDataInStore,
    setPlanPolyDataInStore,
    setSumInStore,
} from "../redux/slices/polydataSlice";

// Import your fictional Stats
import Stats from "../Components/common/Stats";
import IconButton from "@mui/material/IconButton";

import "perfect-scrollbar/css/perfect-scrollbar.css"; // Import the styles
import PerfectScrollbar from "perfect-scrollbar";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Modal } from "@mui/material";

import { getMapConfig } from "../utils/getMapConfig";
import ReportPdf from "../Components/ReportPdf/ReportPdf";

import mapboxgl from "mapbox-gl";
import { PDFViewer } from "@react-pdf/renderer";
import generateChartImage from "../utils/pdf/generateChartSum";
import processBufferArray from "../utils/pdf/processBufferArray";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;


const MapIdName = ({ id }) => {
    return (
        <div
            style={{
                fontSize: "12px",
                position: "absolute",
                top: "0px",
                color: "white",
                left: "0px",
                zIndex: 1000,
                backgroundColor: "#1976D2",
                padding: "5px",
                borderRadius: "0px",
            }}
        >
            {id}
        </div>
    );
};

const ReportPage = ({ langData }) => {
    const center = [139.8127, 35.6729];
    const zoom = 10;
    const [aoisum, setAoiSum] = useState(null);
    const [plan1sum, setPlan1Sum] = useState(null);
    const [plan3sum, setPlan3Sum] = useState(null);
    const [plan2sum, setPlan2Sum] = useState(null);
    const [editorData, setEditorData] = useState({
        1: "",
        2: "",
        3: "",
    });

    const mapAoiRef = useRef(null);
    const mapPlan1Ref = useRef(null);
    const mapPlan2Ref = useRef(null);
    const mapPlan3Ref = useRef(null);
    const [aoiPolygon, setAoiPolygon] = useState();
    const [projDescription, setProjectDescription] = useState();
    const [loading, setLoading] = useState(false);
    const [aoiBufferCoords, setAoiBufferCoords] = useState();
    const [planBufferCoords, setPlanBufferCoords] = useState();
    const selectedBasemap = useSelector((state) => state.basemap.selectedBasemap) || 'mapbox://styles/mapbox/streets-v11';
    const isMounted = useRef(true);
    const dispatch = useDispatch();
    const [selectedModelId, setSelectedModelId] = useState(0);
    const [queryResult, setQueryResult] = useState();
    const [polygonData, setPolygonData] = useState(null);
    const [projectName, setProjectName] = useState();
    const [aoiPolyData, setAoiPolyData] = useState(null);
    const [aoi250PolyData, setAoi250PolyData] = useState(null);
    const [plan1PolyData, setPlan1PolyData] = useState(null);
    const [plan2PolyData, setPlan2PolyData] = useState(null);
    const [plan3PolyData, setPlan3PolyData] = useState(null);
    const [model, setModel] = useState(null);
    const [modelArray, setModelArray] = useState([]);
    const [isFormCollapsed, setIsFormCollapsed] = useState(true);
    const { projectId } = useParams();
    const scrollbarRef = useRef(null);
    const popupRef = useRef(
        new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
    );
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [images, setImages] = useState({
        mapImages: [],
        bufferImages: [],
        chartImage: null,
    });
    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const navigate = useNavigate();
    const [isPdfReady, setIsPdfReady] = useState(false);

    var movement = false;

    // Replace the two separate useEffects with this single optimized one
    useEffect(() => {
        
        const plans = [
            { name: "AOI", polygonsData: aoiPolyData, langName: langData?.aoi },
            { name: "AOI250", polygonsData: aoi250PolyData, langName: langData?.aoi250 },
            { name: "Plan 1", polygonsData: plan1PolyData, langName: langData?.plan1 },
            { name: "Plan 2", polygonsData: plan2PolyData, langName: langData?.plan2 },
            { name: "Plan 3", polygonsData: plan3PolyData, langName: langData?.plan3 },
        ];
        
        const isReady = plans.every((plan) => plan.polygonsData !== null);
        
        const hasRequiredData =
        isReady && aoiPolygon && aoiBufferCoords && planBufferCoords && selectedBasemap;
        
        if (hasRequiredData && isPdfReady === false) {            
            async function fetchImages() {
                const bufferArray = [
                    { name: "2000", polygonsData: aoiBufferCoords },
                    { name: "250", polygonsData: planBufferCoords },
                ];

                setIsPdfLoading(true);

                try {
                    const [bufferMapImage, mapImages, chartImage] = await Promise.all([
                        processBufferArray(bufferArray, selectedBasemap, aoiPolygon),
                        downloadImagesToPdf(
                            aoiPolygon,
                            plans,
                            selectedBasemap,
                            aoiBufferCoords,
                            planBufferCoords
                        ),
                        generateChartImage(aoisum, plan1sum, plan2sum, plan3sum),
                    ]);

                    setImages({
                        mapImages: Array.isArray(mapImages) ? mapImages : [mapImages],
                        bufferImages: Array.isArray(bufferMapImage)
                            ? bufferMapImage
                            : [bufferMapImage],
                        chartImage,
                    });
                } catch (error) {
                    console.error("Error during image fetching:", error);
                } finally {
                    setIsPdfLoading(false);
                }
            }

            fetchImages();
            setIsPdfReady(true);
        }
    }, [
        aoiPolyData,
        aoi250PolyData,
        plan1PolyData,
        plan2PolyData,
        plan3PolyData,
        langData,
        aoiPolygon,
        selectedBasemap,
        aoiBufferCoords,
        planBufferCoords,
        aoisum,
        plan1sum,
        plan2sum,
        plan3sum
    ]);
    


    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        
        getProject(projectId)
            .then((response) => {
                const modelString = response.data[0].model;
                if (response.data) {
                    setEditorData({
                        1: response.data[0].plan1info || "",
                        2: response.data[0].plan2info || "",
                        3: response.data[0].plan3info || "",
                    });
                }
                if (response?.data[0]?.geom) {
                    setAoiPolygon(response.data[0].geom);
                }
                setModel(modelString);

                const modelIdForResult = findFirstCommonElement(modelString)

                setSelectedModelId(modelIdForResult)
                setProjectName(response.data[0].name);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const findFirstCommonElement = useCallback((obj) => {
        // Parse the input object if it's a string
        if (typeof obj === 'string') {
            obj = JSON.parse(obj);
        }
        // Convert the object values to an array of arrays
        const arrays = Object.values(obj);
        // Get the first array as the base for comparison
        const baseArray = arrays[0];
        // Loop through each element in the base array
        for (let element of baseArray) {
            // Check if the element is present in all other arrays
            if (arrays.every(array => array.includes(element))) {
                return element; // Return the first common element found
            }
        }
        return null; // Return null if no common element is found
    }, []); // Empty dependency array since the function doesn't depend on any external values

    // Define outside component
    const useResizeObserver = (ref, callback) => {
        useEffect(() => {
            const observer = new ResizeObserver(callback);
            if (ref.current instanceof Element) {
                observer.observe(ref.current);
            }
            return () => {
                if (ref.current instanceof Element) {
                    observer.unobserve(ref.current);
                }
            };
        }, [ref, callback]);
    };
    
    const handleModelSelectionChange = useCallback((modelId) => {
        setSelectedModelId(modelId);
    }, []); // Only depends on setSelectedModelId which is stable
    
    const handleResize = useCallback(() => {
        if (mapAoiRef.current) mapAoiRef.current.resize();
        if (mapPlan1Ref.current) mapPlan1Ref.current.resize();
        if (mapPlan2Ref.current) mapPlan2Ref.current.resize();
        if (mapPlan3Ref.current) mapPlan3Ref.current.resize();
    }, [mapAoiRef, mapPlan1Ref, mapPlan2Ref, mapPlan3Ref]); // Dependencies are the map refs

    // Apply ResizeObserver using custom hook
    useResizeObserver(mapAoiRef, handleResize);
    useResizeObserver(mapPlan1Ref, handleResize);
    useResizeObserver(mapPlan2Ref, handleResize);
    useResizeObserver(mapPlan3Ref, handleResize);

    useEffect(() => {
        if (scrollbarRef.current) {
            // Initialize the PerfectScrollbar when the component mounts
            const ps = new PerfectScrollbar(scrollbarRef.current, {
                wheelPropagation: true,
            });

            // Cleanup when the component unmounts
            return () => {
                ps.destroy();
            };
        }
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const models = await fetchModels();
                setModelArray(models.data);

                const aoiBuffer = await getBufferCircle(projectId, "aoi");
                setAoiBufferCoords(aoiBuffer.data);

                const planBuffer = await getBufferCircle(projectId, "plan");
                setPlanBufferCoords(planBuffer.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Implement more comprehensive error handling here
            }
        };

        if (projectId) {
            fetchData();
        }
    }, [projectId]);

    useEffect(() => {
        const fetchAoi250Data = async () => {
            try {
                const response = await getAoi250Data(projectId);
                const data = response.data.data; // Access the nested 'data' array

                if (Array.isArray(data)) {
                    const polygonsData = data.map((item) => {
                        const { geom, fid, HSI_Range } = item;
                        const uniqueId = uuidv4();
                        return {
                            coordinates: geom ? geom.coordinates : [],
                            stats: {
                                id: uniqueId,
                                type: geom ? geom.type : null,
                                fid,
                                hsi: HSI_Range,
                            },
                        };
                    });
                    setAoi250PolyData(polygonsData);
                } else {
                    console.error("Expected data to be an array, but it was not.");
                }
            } catch (error) {
                console.error("Error fetching AOI 250 data:", error);
                // Implement more comprehensive error handling here
            }
        };

        if (projectId) {
            fetchAoi250Data();
        }
    }, [projectId]);


    useEffect(() => {
        getResult(projectId, selectedModelId)
            .then((response) => {
                setQueryResult(response.data);
                const planIdMap = {
                    0: setAoiSum,
                    1: setPlan1Sum,
                    2: setPlan2Sum,
                    3: setPlan3Sum,
                };
    
                // Filter out any entries where geom is missing.
                const filteredPolygonsData = response.data.filter(
                    (polygon) => polygon.model_id === selectedModelId && polygon.geom
                );
    
                const polygonsData = filteredPolygonsData.map((item) => {
                    const { plan_id, geom, n_v1, n_v2, n_v3, fid, hsi, cartodb_id } = item;
                    
                    // Calculate sums for each plan
                    const sumsData = response.data.reduce((acc, currentItem) => {
                        const { plan_id, sum } = currentItem;
                        const setSum = planIdMap[plan_id];
                        if (setSum) setSum(sum);
                        acc[`${plan_id}sum`] = sum;
                        return acc;
                    }, {});
    
                    // Dispatch new actions to store sums
                    dispatch(setSumInStore({ type: 'aoi', data: sumsData["0sum"] }));
                    dispatch(setSumInStore({ type: 'plan1', data: sumsData["1sum"] }));
                    dispatch(setSumInStore({ type: 'plan2', data: sumsData["2sum"] }));
                    dispatch(setSumInStore({ type: 'plan3', data: sumsData["3sum"] }));
    
                    const uniqueId = uuidv4();
                    return {
                        coordinates: geom?.coordinates,
                        stats: {
                            id: uniqueId,
                            type: geom?.type,
                            nv1: n_v1,
                            nv2: n_v2,
                            nv3: n_v3,
                            fid,
                            hsi,
                            name: `Polygon ${cartodb_id}`,
                            planNumber: Number(plan_id), // convert to number
                        },
                    };
                });
    
                // Log all polygons data to the console for debugging.
                // console.log("Polygons Data:", polygonsData);
    
                setPolygonData(polygonsData);
    
                // Separate polygons by plan.
                const aoiPolygons = polygonsData.filter(
                    (item) => item.stats.planNumber === 0
                );
                setAoiPolyData(aoiPolygons);
                dispatch(setAOIPolyDataInStore(aoiPolygons));
    
                const plan1Polygons = polygonsData.filter(
                    (item) => item.stats.planNumber === 1
                );
                setPlan1PolyData(plan1Polygons);
                dispatch(setPlanPolyDataInStore({ plan: '1', data: plan1Polygons }));
    
                const plan2Polygons = polygonsData.filter(
                    (item) => item.stats.planNumber === 2
                );
                setPlan2PolyData(plan2Polygons);
                dispatch(setPlanPolyDataInStore({ plan: '2', data: plan2Polygons }));
    
                const plan3Polygons = polygonsData.filter(
                    (item) => item.stats.planNumber === 3
                );
                setPlan3PolyData(plan3Polygons);
                dispatch(setPlanPolyDataInStore({ plan: '3', data: plan3Polygons }));
            })
            .catch((error) => {
                console.error("Error fetching data for projectId:", projectId, error);
            });
    }, [selectedModelId, projectId, dispatch]);
    

    useEffect(() => {
        getProject(projectId)
            .then((response) => {
                const modelString = response.data[0].model;
                if (response.data) {
                    const projDescription = response.data[0].description;
                    setProjectDescription(projDescription);
                    setEditorData({
                        1: response.data[0].plan1info || "",
                        2: response.data[0].plan2info || "",
                        3: response.data[0].plan3info || "",
                    });
                }
                if (response?.data[0]?.geom) {
                    setAoiPolygon(response.data[0].geom);
                }
                setModel(modelString);
                const parsedModelString = JSON.parse(modelString);
                const modelIds = Object.values(parsedModelString).flat();

                setProjectName(response.data[0].name);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const handleBackButtonClick = () => {
        navigate(`/edit/${projectId}`);
    };

    const toggleFormCollapse = () => {
        setIsFormCollapsed((prev) => !prev);
    };

    const handleMapMove = (mapRef, newCenter, newZoom) => {
        const duration = 0;
        if (!movement && mapRef) {
            const targetRefs = [mapAoiRef, mapPlan1Ref, mapPlan2Ref, mapPlan3Ref];
            const currentIndex = targetRefs.findIndex((ref) => ref === mapRef);
            if (currentIndex !== -1) {
                movement = true;

                for (let i = 0; i < targetRefs.length; i++) {
                    if (i !== currentIndex) {
                        if (targetRefs[i].current) {
                            targetRefs[i].current.easeTo({
                                center: newCenter,
                                zoom: newZoom,
                                duration,
                            });
                        }
                    }
                }

                movement = false;
            }
        }
    };

    const createMap = useCallback((containerId, initialCenter, initialZoom, mapRef) => {
        if (!containerId) {
            console.error("Container ID is required to create a map.");
            return;
        }

        const map = new mapboxgl.Map(
            getMapConfig(selectedBasemap, containerId, initialCenter, initialZoom)
        );

        map.addControl(new mapboxgl.NavigationControl(), "bottom-right")
       .addControl(new mapboxgl.ScaleControl(), "bottom-left");

        map.on("move", () => {
            if (!movement) {
                handleMapMove(mapRef, map.getCenter().toArray(), map.getZoom());
            }
        });

        mapRef.current = map;
        return map;
    }, [selectedBasemap])

    const setupMapData = (mapInstance, polyData, bufferCoords, aoiPolygon, popupRef) => {
        if (!mapInstance || !Array.isArray(polyData) || polyData.length === 0 || !aoiPolygon) return;

        addPolygonsToMap(mapInstance, polyData, bufferCoords);
        addPolygonOutlineToMap(mapInstance, aoiPolygon);

        polyData.forEach(polygon => {
            const { stats } = polygon;
            const popupContent = `<p>HSI: ${stats.hsi}</p>`;
            addPopupToMap(mapInstance, `polygon-layer-${stats.id}`, popupContent, popupRef);
        });
    };

    const useMapEffect = (containerId, mapRef, polyData, bufferCoords, aoiPolygon, popupRef) => {
        
        useEffect(() => {            
            const mapInstance = createMap(containerId, center, zoom, mapRef);
            setupMapData(mapInstance, polyData, bufferCoords, aoiPolygon, popupRef);
        }, [selectedModelId, polyData, selectedBasemap, aoiPolygon]);
    };

    useMapEffect("map-aoi", mapAoiRef, aoiPolyData, aoiBufferCoords, aoiPolygon, popupRef);
    useMapEffect("map-plan1", mapPlan1Ref, plan1PolyData, planBufferCoords, aoiPolygon, popupRef);
    useMapEffect("map-plan2", mapPlan2Ref, plan2PolyData, planBufferCoords, aoiPolygon, popupRef);
    useMapEffect("map-plan3", mapPlan3Ref, plan3PolyData, planBufferCoords, aoiPolygon, popupRef);

    function handleClose() {
        setIsModalOpen(false);
    }

    function handlePdfDownload() {
        if (isPdfLoading) {
            setToastOpen(true)
            return;
        }
        setIsModalOpen(true);
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };


    return (
        <div
            style={{
                display: "flex",
                height: "calc(100vh - 64px)",
                overflow: "auto",
                transition: "flex 0.3s ease-in-out, padding 0.3s ease-in-out",
            }}
        >
            {
                <Snackbar
                    open={toastOpen}
                    autoHideDuration={5000}
                    onClose={handleAlertClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <MuiAlert onClose={handleAlertClose} severity="info" elevation={6} variant="filled">
                        {langData.PDFisGenerating}
                    </MuiAlert>
                </Snackbar>
            }
            <div
                style={{
                    flex: "1 1 auto",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridTemplateRows: "repeat(2, 1fr)",
                    gap: "2.5px",
                }}
            >
                <div>
                    <div
                        id="map-aoi"
                        style={{ width: "100%", height: "100%" }}
                        ref={mapAoiRef}
                    >
                        <MapIdName id={langData.aoi} />
                    </div>
                </div>

                <div>
                    <div
                        id="map-plan1"
                        style={{ width: "100%", height: "100%" }}
                        ref={mapPlan1Ref}
                    >
                        <MapIdName id={langData.plan1} />
                    </div>
                </div>

                <div>
                    <div
                        id="map-plan2"
                        style={{ width: "100%", height: "100%" }}
                        ref={mapPlan2Ref}
                    >
                        <MapIdName id={langData.plan2} />
                    </div>
                </div>

                <div>
                    <div
                        id="map-plan3"
                        style={{ width: "100%", height: "100%" }}
                        ref={mapPlan3Ref}
                    >
                        <MapIdName id={langData.plan3} />
                    </div>
                </div>
            </div>

            {loading && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            )}

            <div style={{ padding: "20px", position: "relative", maxWidth: "300px" }}>
                <IconButton
                    style={{ position: "absolute", top: "5px", right: "0px" }}
                    onClick={toggleFormCollapse}
                >
                    {isFormCollapsed ? (
                        <IconLayoutSidebarLeftExpand />
                    ) : (
                        <IconLayoutSidebarRightExpand />
                    )}
                </IconButton>
                {isFormCollapsed && (
                    <div className="unhighlight-stats" style={{ height: "100%" }}>
                        <Stats
                            projectId={projectId}
                            onBackButtonClick={handleBackButtonClick}
                            modelString={model}
                            modelArray={modelArray}
                            name={projectName}
                            jsonData={queryResult}
                            download={handlePdfDownload}
                            langData={langData}
                            plansDescription={editorData}
                            onModelSelectionChange={handleModelSelectionChange}
                        />
                    </div>
                )}
            </div>

            <div>
                <Modal
                    open={isModalOpen}
                    onClose={handleClose}
                    aria-labelledby="pdf-preview"
                    aria-describedby="pdf-preview-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            height: '80%',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{ alignSelf: 'flex-end', mb: 1 }}
                        >
                            {langData.close}
                        </Button>
                        <PDFViewer style={{ width: '100%', height: '100%' }}>
                            <ReportPdf
                                projectName={projectName}
                                projDescription={projDescription}
                                editorData={editorData}
                                selectedModelId={selectedModelId}
                                modelArray={modelArray}
                                tableData={[aoisum, plan1sum, plan2sum, plan3sum]}
                                langData={langData}
                                images={images}
                            />
                        </PDFViewer>
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export default ReportPage;
