import Navbar from '../Components/common/Navbar';

const MainLayout = ({ children, isAdmin, langData,currentLanguage }) => {
  return (
    <>
      <Navbar isAdmin={isAdmin} langData={langData} currentLanguage={currentLanguage} />
      {children}
    </>
  );
};

export default MainLayout;
