import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message: null,
    type: null,
};

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError(state, action) {
            state.message = action.payload.message;
            state.type = action.payload.type || 'general';
        },
        clearError(state) {
            state.message = null;
            state.type = null;
        },
    },
});

export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
