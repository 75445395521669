import React from 'react';

const DonutChart = ({ progress, fail, total }) => {
  const successPercent = total > 0 ? (progress / total) * 100 : 0;
  const failPercent = total > 0 ? (fail / total) * 100 : 0;
  const pendingPercent = total > 0 ? Math.max(0, 100 - (successPercent + failPercent)) : 0;

  const containerStyle = {
    width: '100px',
    height: '10px',
    borderRadius: '5px',
    overflow: 'hidden',
    backgroundColor: '#e6e6e6',
    position: 'relative',
  };

  const segmentStyle = (left, width, color, zIndex = 1) => ({
    position: 'absolute',
    left: `${left}%`,
    width: `${width}%`,
    height: '100%',
    backgroundColor: color,
    transition: 'width 0.5s ease',
    zIndex,
  });

  // Animated pending segment style using a bright orange gradient
  const pendingStyle = {
    position: 'absolute',
    left: `${successPercent + failPercent}%`,
    width: `${pendingPercent}%`,
    height: '100%',
    borderRadius: '5px',
    background: 'linear-gradient(90deg, rgba(255,165,0,0.3) 25%, rgba(255,165,0,0.7) 50%, rgba(255,165,0,0.3) 75%)',
    backgroundSize: '200% 100%',
    animation: 'loading 1.5s linear infinite',
    zIndex: 1,
  };

  return (
    <div style={containerStyle}>
      {total > 0 && successPercent > 0 && (
        <div style={segmentStyle(0, successPercent, 'green', 2)} />
      )}
      {total > 0 && failPercent > 0 && (
        <div style={segmentStyle(successPercent, failPercent, 'red', 2)} />
      )}
      {total > 0 && pendingPercent > 0 && <div style={pendingStyle} />}
      <style>
        {`
          @keyframes loading {
            0% { background-position: -100% 0; }
            100% { background-position: 100% 0; }
          }
        `}
      </style>
    </div>
  );
};

export default DonutChart;
