
import React, { useRef, useEffect, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { getMapConfig } from '../../utils/getMapConfig';
import { useSelector } from 'react-redux';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapComponent = ({ cartoData, selectedShape, setSelectedShape }) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null); // Reference to the map instance
    const popupRef = useRef(new mapboxgl.Popup({ closeButton: false, closeOnClick: false })); // Reference to the popup
    const mapState = useRef({ center: [139.6917, 35.6895], zoom: 11 }); // Ref to store the map state

    const selectedBasemap = useSelector((state) => state.basemap.selectedBasemap) || 'mapbox://styles/mapbox/streets-v11';

    const addCartoLayerToMap = useCallback((map, data) => {
        if (map.isStyleLoaded()) {
            if (map.getSource('carto-data')) {
                map.removeLayer('carto-layer');
                map.removeSource('carto-data');
            }

            map.addSource('carto-data', {
                type: 'geojson',
                data: data,
            });

            map.addLayer({
                id: 'carto-layer',
                type: 'circle',
                source: 'carto-data',
                paint: {
                    'circle-color': ['get', 'fillColor'],
                    'circle-radius': 7,
                    'circle-opacity': 0.8,
                },
            });

            // Add event listeners for popups
            map.on('mouseenter', 'carto-layer', (e) => {
                const { name } = e.features[0].properties;
                const coordinates = e.features[0].geometry.coordinates.slice();
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                const popupContent = `<div>${name}</div>`;

                popupRef.current
                    .setLngLat(coordinates)
                    .setHTML(popupContent)
                    .addTo(map);
            });

            map.on('click', 'carto-layer', (e) => {
                const coordinates = e.features[0].geometry.coordinates.slice();
                setSelectedShape(e.features[0].properties.id);
                map.flyTo({
                    center: coordinates,
                    zoom: 14,
                    duration: 1500
                });
            });

            // Cleanup the popup when the mouse leaves the feature
            map.on('mouseleave', 'carto-layer', () => {
                popupRef.current.remove();
            });
        }
    }, [setSelectedShape]);

    // Initialize the map instance only once
    useEffect(() => {
        const initializeMap = () => {
            // Function from utils for map config
            const mapConfig = getMapConfig(selectedBasemap, mapContainerRef.current, mapState.current.center, mapState.current.zoom);

            // Initialize the map with the appropriate configuration
            mapRef.current = new mapboxgl.Map(mapConfig);

            const navControl = new mapboxgl.NavigationControl();
            mapRef.current.addControl(navControl, 'top-left');

            mapRef.current.on('load', () => {
                if (cartoData) {
                    addCartoLayerToMap(mapRef.current, cartoData);
                }

                // Restore the map position
                mapRef.current.flyTo({
                    center: mapState.current.center,
                    zoom: mapState.current.zoom
                });
            });
        };

        if (!mapRef.current) {
            initializeMap();
        }

        return () => {
            if (mapRef.current) {
                mapState.current = {
                    center: mapRef.current.getCenter().toArray(),
                    zoom: mapRef.current.getZoom()
                };
                mapRef.current.remove();
                mapRef.current = null;
            }
        };
    }, [selectedBasemap, cartoData, addCartoLayerToMap]);


    useEffect(() => {
        if (selectedShape && mapRef.current && cartoData) {
            const shape = cartoData.features.find(
                (feature) => feature.properties.id === selectedShape
            );

            if (shape) {
                const coordinates = shape.geometry.coordinates.slice();
                const { name } = shape.properties;

                // Correct for longitudes that cross the 180° meridian
                while (Math.abs(mapRef.current.getCenter().lng - coordinates[0]) > 180) {
                    coordinates[0] += mapRef.current.getCenter().lng > coordinates[0] ? 360 : -360;
                }

                // Show the popup
                popupRef.current
                    .setLngLat(coordinates)
                    .setHTML(`<div>${name}</div>`)
                    .addTo(mapRef.current);

                // Fly to the shape
                mapRef.current.flyTo({
                    center: coordinates,
                    zoom: 14,
                    speed: 0.1,
                    curve: 1.5,
                    duration: 3500,
                });
            }
        } else if (mapRef.current) {
            // Zoom out to the center when no shape is selected
            popupRef.current.remove();
            mapRef.current.flyTo({
                center: mapState.current.center,
                zoom: mapState.current.zoom,
                speed: 0.5,
                curve: 1.5,
                duration: 1500,
            });
        }
    }, [selectedShape, cartoData]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
                <div ref={mapContainerRef} style={{ flex: '1', position: 'relative', overflow: 'hidden' }}></div>
                <div
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 1,
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        padding: '5px',
                    }}
                ></div>
            </div>
        </div>
    );
};

export default MapComponent;
