import { useState, useEffect } from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import styles from './style';
import { getCurrentDate } from '../../utils/functions';
import Header from './Header';
import getModelInfo from './utils/getModelInfo';

// Main Component
const FirstPage = ({
    projectName, 
    projDescription,
    editorData,
    selectedModelId,
    modelArray,
    tableData,
    langData,
}) => {
    const [ modelInfo, setModelInfo ] = useState();
    const currentDate = getCurrentDate()

    useEffect(() => {
        setModelInfo(getModelInfo(selectedModelId, modelArray));
    }, [selectedModelId, modelArray]);

    return (
        <Page size="A4" style={styles.page}>
              {/* Reusable Header */}
            <Header projectName={projectName} currentDate={currentDate} />

            {/* Content Area */}
            <View style={styles.content}>
               
                {/* section 1 */}
                <View style={styles.section1}>
                    {/* project overview */}
                    <View style={styles.section1Row}>
                        <Text style={styles.section1Title}>{langData.projectOverView}</Text>
                        <Text style={styles.section1Content}>{projDescription}</Text>
                    </View>
                    {/* project model */}
                    <View style={styles.section1Row}>
                        <Text style={styles.section1Title}>{langData.modelColumn}</Text>
                        <Text style={styles.section1Content}>{modelInfo?.title}</Text>
                    </View>
                
                    <View style={styles.section1Row}>
                        <Text style={styles.section1Title}>{langData.plan1}</Text>
                        <Text style={styles.section1Content}>{editorData?.[1]}</Text>
                    </View>
                    <View style={styles.section1Row}>
                        <Text style={styles.section1Title}>{langData.plan2}</Text>
                        <Text style={styles.section1Content}>{editorData?.[2]}</Text>
                    </View>
                    <View style={styles.section1Row}>
                        <Text style={styles.section1Title}>{langData.plan3}</Text>
                        <Text style={styles.section1Content}>{editorData?.[3]}</Text>
                    </View>
                   
                </View>

                {/* Measurements Table */}
                <View style={styles.table}>
                    {/* Before Construction */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableHeaderLabel}>
                            <Text style={styles.contentText}>建設前</Text>
                        </View>
                        <View style={styles.tableHeaderContent}>
                            <Text style={styles.contentText}>{tableData[0]}</Text>
                        </View>
                    </View>

                    {/* After Construction */}
                    <View style={[styles.tableRow, { borderBottomWidth: 0 }]}>
                        <View style={styles.tableHeader}>
                            <Text style={styles.contentText}>建設後</Text>
                        </View>
                        <View style={styles.tableContent}>
                            <View style={[styles.tableRow, {borderTopWidth: 0, borderRightWidth: 0}]}>
                                <Text style={styles.contentLabel}>案1</Text>
                                <Text style={styles.contentValue}>{tableData[1]}</Text>
                            </View>
                            <View style={[styles.tableRow, {borderTopWidth: 0, borderRightWidth: 0}]}>
                                <Text style={styles.contentLabel}>案2</Text>
                                <Text style={styles.contentValue}>{tableData[2]}</Text>
                            </View>
                            <View style={[styles.tableRow, {borderTopWidth: 0, borderRightWidth: 0}]}>
                                <Text style={styles.contentLabel}>案3</Text>
                                <Text style={styles.contentValue}>{tableData[2]}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )}
                fixed
            />
        </Page>
    );
};

export default FirstPage;