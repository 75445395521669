import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { store } from '../redux/store';
import { setError } from '../redux/slices/errorSlice';

const axiosAPI = axios.create({
    baseURL: `${process.env.REACT_APP_UE_API}`,
});

// Add a request interceptor to add the token before each request
axiosAPI.interceptors.request.use(
    async (config) => {
        try {
            const session = await fetchAuthSession();
            const token = session?.tokens?.accessToken?.toString();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        } catch (error) {
            console.error('Error getting session:', error);
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor to handle errors and retries
axiosAPI.interceptors.response.use(
    (response) => response,
    async (error) => {        
        const originalRequest = error.config;        
        // Handle 401 Unauthorized error
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const session = await fetchAuthSession({ forceRefresh: true });
                const newAccessToken = session?.tokens?.accessToken?.toString();
                if (newAccessToken) {
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosAPI(originalRequest);
                } else {
                    throw new Error('Session is empty. Logging out.');
                }
            } catch (authError) {
                localStorage.clear();
                sessionStorage.clear();
                window.location.reload();
            }
        }
        
        // Handle 500 or network-related errors
        if (
            error.response?.status === 500 ||
            error.code === 'ERR_NETWORK' ||
            error.code === 'ERR_CONNECTION_REFUSED'
        ) {
            store.dispatch(setError({ message: 'serverError', type: 'server'}));
        }

        return Promise.reject(error);
    }
);

export default axiosAPI;
