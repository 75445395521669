export const getMapConfig = (selectedBasemap, container, center, zoom) => {
    const isMapboxStyle = selectedBasemap.startsWith('mapbox://styles/');
    const mapConfig = {
      container,
      center,
      zoom,
      preserveDrawingBuffer: true,
    };
  
    if (isMapboxStyle) {
      // Use Mapbox style directly
      mapConfig.style = selectedBasemap;
    } else {
      // Use custom tile URL
      const tileUrl = Array.isArray(selectedBasemap) ? selectedBasemap : [selectedBasemap];
      mapConfig.style = {
        version: 8,
        sources: {
          "custom-tiles": {
            type: "raster",
            tiles: tileUrl,
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "custom-layer",
            type: "raster",
            source: "custom-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      };
    }
  
    return mapConfig;
  };
  