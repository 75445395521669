/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UploadGeoTIFFDialog from "../common/GeotiffDialogBox";

import {
  updateProject,
  createProject,
  fetchCategories,
  batchModeling,
  fetchModels,
} from "../../utils/carto";

// Import FileSelectDialog component
import MapTabs from "../common/MapTabs";
import { setDataChanged } from "../../redux/slices/dataChangeSlice";
import { useDispatch } from "react-redux";
import DonutChart from "../common/DonutChart";
import SelectV2 from "react-select";
import { useSocket } from "../../Context/SocketContext";
import io from "socket.io-client"

const ProjectEditForm = ({
  projects,
  method,
  onProjectDataChange,
  setSelectedTab,
  plan,
  langData,
  buttonState,
  memoizedFetchResult,
  setResultButton
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialProject = {
    name: "",
    description: "",
    source: "",
  };
  const [project, setProject] = useState(initialProject);
  const [errors, setErrors] = useState({});

  const [categoryOptions, setCategoryOptions] = useState([]);

  const [saveAndRunButtonDisabled, setSaveAndRunButtonDisabled] =
    useState(false);
  const [hoverMessage, setHoverMessage] = useState("");

  const [uploadSourceTable, setUploadSourceTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modelOptions, setModelOptions] = useState([]); // State for dropdown values
  const [geoTIFFDialogOpen, setGeoTIFFDialogOpen] = useState(false);
  const [selectedModels, setSelectedModels] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  // const socket = useSocket();
  const [socketData, setSocketData] = useState(null);
  const { projectId } = useParams();
  const [isReceiving, setIsReceiving] = useState(false); // New state to track if messages are being received

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: "#ccc", // use the same color as your other form elements
      boxShadow: "none",    // remove the focus box-shadow
      "&:hover": {
        borderColor: "#ccc",
      },
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    menu: (base) => ({
      ...base,
      overflow: 'visible',
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: 'none',
      overflow: 'visible',
    }),
  };

  const fetchCategoryOptions = useCallback(async () => {
    try {
      const options = await fetchCategories();
      setCategoryOptions(options);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, []);

  const fetchModelData = useCallback(async () => {
    try {
      const models = await fetchModels();
      const uniqueModels = Array.from(
        new Set(models.data.map((model) => model.model_id))
      ).map((model_id) =>
        models.data.find((model) => model.model_id === model_id)
      );
      setModelOptions(uniqueModels);
    } catch (error) {
      console.error("Error fetching models:", error);
    }
  }, []);

  useEffect(() => {
    fetchCategoryOptions();
    fetchModelData();
  }, [fetchCategoryOptions, fetchModelData]);

  useEffect(() => {
    if (selectedModels.length === 0) {
      setSaveAndRunButtonDisabled(true);
      setHoverMessage(langData.selectOneModalWarning);
    } else if (!projects || projects.geom === null || projects.geom === undefined) {
      
      setSaveAndRunButtonDisabled(true);
      setHoverMessage(langData.pleaseDrawAOIForModeling);
    } else {
      setSaveAndRunButtonDisabled(false);
      setHoverMessage("");
    }
  }, [selectedModels, projects]);

// Inside the useEffect for setting selectedModels in ProjectEditForm component
useEffect(() => {
  if (projects) {
    setProject({
      name: projects.name,
      description: projects.description || "",
      source: projects.source || "",
    });

    if (projects.model !== undefined || null) {
      const parsedModel = JSON.parse(projects.model) || {};
      
      const selectedModelsForDropdown = [];

      for (let i = 0; i < 1; i++) {
        const selectedModelIds = parsedModel[i] || [];
        // Add a check to verify if selectedModelIds is an array before using includes
        if (Array.isArray(selectedModelIds)) {
          const selectedModelsForItem = modelOptions.filter((model) =>
            selectedModelIds.includes(model.model_id)
          );
          selectedModelsForDropdown.push(...selectedModelsForItem);
        }
      }

      setSelectedModels(selectedModelsForDropdown);
    }
  }
}, [projects, modelOptions]);

useEffect(() => {
  const socket = io(`${process.env.REACT_APP_UE_SOCKET_PORT}`);
  socket.on("jobStatus", (data) => {

    const receivedProjectId = data.projectId;
    const foundProject = projects.cartodb_id === receivedProjectId;

    if (foundProject) {
      setSocketData(data);
      setIsReceiving(true);
      setSaveAndRunButtonDisabled(true);
      setResultButton(true);
    }

    if (data.status === "failure") {
      setIsReceiving(false);
    }

    if (data.status === "success") {
      setSaveAndRunButtonDisabled(false);
      setIsReceiving(false);
      memoizedFetchResult();
      setResultButton(false);
    }
  });

  return () => {
    socket.disconnect();
  };
}, [projects, socketData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setProject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBackClick = () => {
    dispatch(setDataChanged(true));
    navigate("/");
  };

  const handleReportClick = () => {
    const projectId = projects.cartodb_id; // Get the project ID from the current project
    if (projectId) {
        navigate(`/report/${projectId}`);
    } else {
        console.error("Project ID is missing");
    }
};





  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!project.name || !project.name.trim()) {
      validationErrors.name = langData.nameIsRequired;
    }

    setErrors(validationErrors);
    setIsLoading(true); // Start loading

    if (Object.keys(validationErrors).length === 0) {
      try {
        // Convert selectedModels to the desired format
        const formattedModel = JSON.stringify(
          selectedModels.reduce((acc, model) => {
            for (let i = 0; i < 4; i++) {
              acc[i] = acc[i] || [];
              acc[i].push(model.model_id);
            }
            return acc;
          }, {})
        );

        // Update the project object with the formatted model
        const updatedProject = { ...project, model: formattedModel };

        if (method === "put") {
          const response = await updateProject(
            projects.cartodb_id,
            updatedProject
          );
          if (response.status === 200) {
            setSuccessMessage(langData.requestSuccessful); // Set success message
            // navigate('/');
          } else {
            console.error("Error updating record:", response.data);
          }
        } else if (method === "post") {
          const response = await createProject(updatedProject);
          if (response.status === 200) {
            // Assuming the API returns the new project object in response.data
            const createdProject = response.data.data;
            // console.log("Created project:", createdProject);
            if (onProjectDataChange) {
              onProjectDataChange(createdProject);
            }
            setSuccessMessage(langData.requestSuccessful); // Set success message
            // Navigate to the edit page using the cartodb_id as the project id
            navigate(`/edit/${createdProject.cartodb_id}`);
          }
        }
      } catch (error) {
        setSuccessMessage(langData.projectAlreadyExists); // Set success message
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      setIsLoading(false); // Stop loading if there are validation errors
    }
  };

  const handleModelChange = (selectedOptions) => {
    if (selectedOptions.length <= 3) {
      const updatedModels = {};
      for (let i = 0; i < 4; i++) {
        const selectedModelsForItem = selectedOptions
          .filter((model) => model.model_id !== undefined) // Filter out any undefined values
          .slice(0, 3) // Take the first 3 selected models
          .map((model) => model.model_id);

        updatedModels[i] = selectedModelsForItem;
      }

      setSelectedModels(selectedOptions);
      setWarningMessage(""); // Clear warning message if within the limit
    } else {
      setWarningMessage(langData.maxOptionWaring); // Set warning message
    }

    // Check if more than three options are selected
  };

  const handleSaveAndRun = async (e) => {
    try {
      e.preventDefault();
  
      // Perform the save action here
      await handleSubmit(e); // Call the existing handleSubmit function
  
      // Convert selectedModels to the desired format for batchModeling
      const formattedModel = selectedModels.reduce((acc, model) => {
        for (let i = 0; i < 4; i++) {
          acc[i] = acc[i] || [];
          acc[i].push(model.model_id);
        }
        return acc;
      }, {});
  
      // Transform formattedModel into an array of objects for the previous payload format
      const previousFormatModel = Object.keys(formattedModel).map((key) => ({
        [key]: formattedModel[key],
      }));
  
      const requestData = {
        cartodbId: projects.cartodb_id,
        model: JSON.stringify(previousFormatModel), // Convert the array of objects to a string
      };
  
      await batchModeling(requestData); // Send formatted payload to batchModeling
      // console.log("Save and Run action completed successfully:", requestData);
    } catch (error) {
      console.error("An error occurred while saving and running:", error);
    }
  };
  
  

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
      <h2 style={{
        margin: "0",
        flexGrow: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}>
        {projects.name}
      </h2>


        <Button
          onClick={handleBackClick}
          variant="text"
          startIcon={<ArrowBackIcon />}
          style={{ minWidth: "fit-content" }}
        >
          {langData.back}
        </Button>
      </div>

      <form onSubmit={handleSubmit}>
        <TextField
          name="name"
          label={langData.name}
          value={project.name || ""} // Set a default value if undefined
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
          error={Boolean(errors.name)}
          helperText={errors.name}
        />

        <TextField
          name="description"
          label={langData.description}
          value={project.description || ""} // Set a default value if undefined
          onChange={handleInputChange}
          fullWidth
          multiline
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel shrink htmlFor="category-label" style={{ backgroundColor:"#f9f9f9" }}>
            {langData.source}
          </InputLabel>
          <Select
            name="source"
            value={
              categoryOptions.includes(project.source) ? project.source : ""
            }
            onChange={handleCategoryChange}
            inputProps={{ id: "category-label" }}
            required={!uploadSourceTable}
            disabled={uploadSourceTable}
          >
            <MenuItem value="">
              <em>{langData.selectASource}</em>
            </MenuItem>
            {categoryOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
  <InputLabel shrink htmlFor="model-label" sx={{ backgroundColor: "#f9f9f9" }}>
    {langData.model}
  </InputLabel>
  <SelectV2
    name="model"
    value={selectedModels}
    onChange={handleModelChange}
    isMulti
    options={modelOptions}
    getOptionLabel={(option) => option.title}
    getOptionValue={(option) => option.model_id}
    styles={customSelectStyles}
    menuPortalTarget={document.body}
  />
</FormControl>
        {warningMessage && (
          <Typography variant="caption" color="error">
            {warningMessage}
          </Typography>
        )}
        {isLoading ? (
          <CircularProgress color="primary" /> // Show loader while isLoading is true
        ) : (
          successMessage && (
            <Snackbar
              open={!!successMessage}
              autoHideDuration={2000}
              onClose={() => setSuccessMessage("")}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              message={successMessage}
            />
          )
        )}
        {/* Move the Save button below the toggle button */}

        {!Array.isArray(projects) && (
          <MapTabs setSelectedTab={setSelectedTab} project={projects} langData ={langData} />
        )}

        {/* Move the Save button below the MapTabs */}
        <div
          style={{
            marginTop: "10px",
            marginBottom: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <Button type="submit" variant="contained" color="primary">
              {langData.save}
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={saveAndRunButtonDisabled}
              title={hoverMessage}
              onClick={handleSaveAndRun}
            >
              {langData.saveAndRun}
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={buttonState}
              onClick={handleReportClick}
            >
              {langData.report}
            </Button>
          </div>

          {socketData && (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
    }}
  >
    {hoverMessage && (
      <Typography variant="caption" color="error">
        {hoverMessage}
      </Typography>
    )}
    {socketData.completed === socketData.total && socketData.completed !== 0 ? (
      // Display a tick mark icon in green color when socketData.completed and socketData.total are equal and not zero
      <CheckCircleIcon
        style={{ color: "green", marginRight: "5px" }}
      />
    ) : (
      // Always display DonutChart when socketData.completed and socketData.total are not equal or both are zero
      <DonutChart
        progress={socketData.completed}
        fail={socketData.failureCnt}
        total={socketData.total}
      />
    )}
    <Typography>{`${socketData.completed}/${socketData.total}`}</Typography>
  </div>
)}

        </div>
      </form>

      <UploadGeoTIFFDialog
        langData={langData}
        open={geoTIFFDialogOpen}
        onClose={() => setGeoTIFFDialogOpen(false)}
        planId={plan}
      />
    </div>
  );
};

export default ProjectEditForm;
