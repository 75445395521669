import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBasemap } from '../../redux/slices/basemapSlice';
import { getBasemapsData } from '../../utils/carto';

const BasemapSelector = ({ onChange,langData }) => {
  const dispatch = useDispatch();
  const selectedBasemap = useSelector(state => state.basemap.selectedBasemap);
  const [basemaps, setBasemaps] = useState([]);
  const basemapsList = useSelector(state => state.basemap.basemaps);
  const [currentLanguage, setCurrentLanguage] = useState('ja'); // Default to Japanese

  useEffect(() => {
    // Fetch basemaps data from the API
    getBasemapsData().then(response => {
      if (response.data && response.data.length > 0) {
        setBasemaps(response.data);

        // Ensure selectedBasemap is valid
        const isValidBasemap = response.data.some(basemap => basemap.link === selectedBasemap);
        if (!isValidBasemap) {
          dispatch(setSelectedBasemap(response.data[0]?.link || ''));
        }
      }
    });
  }, [dispatch, selectedBasemap, basemapsList]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const langParam = params.get("lang");
    setCurrentLanguage(langParam === 'en' ? 'en' : 'ja');
  }, []);

  const handleChange = (event) => {
    const newValue = event.target.value;
    dispatch(setSelectedBasemap(newValue));
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Box display="flex" alignItems="center" sx={{ minWidth: '150px', marginRight: '10px' }}>
      <span style={{ color: 'white', marginRight: '8px' }}>{langData.baseMap}:</span>
      <Select
        value={basemaps.some(basemap => basemap.link === selectedBasemap) ? selectedBasemap : ''}
        onChange={handleChange}
        sx={{ color: 'white' }}
      >
        {basemaps.map((basemap) => (
          <MenuItem key={basemap.id} value={basemap.link}>
            {currentLanguage === 'en' ? basemap.enlabel : basemap.jplabel}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default BasemapSelector;
