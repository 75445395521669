// routes.js
import React from "react";
import { Route, Navigate } from "react-router-dom";
import HomePageLayout from "./Pages/HomePage";
import EditPageWithMap from "./Pages/EditPageWithMap";
import Report from "./Pages/ReportPage";
import ChartsPage from "./Pages/ChartsPage";
import Dashboard from "./Components/common/Dashboard";
import MainLayout from "./layouts/MainLayout";

const routes = (isAdmin, langData,currentLanguage) => [
  <Route
    key="home"
    exact
    path="/"
    element={
      <MainLayout isAdmin={isAdmin} langData={langData} currentLanguage={currentLanguage}>
        <HomePageLayout langData={langData} />
      </MainLayout>
    }
  />,
  <Route
    key="report"
    exact
    path="/report/:projectId"
    element={
      <MainLayout isAdmin={isAdmin} langData={langData} currentLanguage={currentLanguage}>
        <Report langData={langData} />
      </MainLayout>
    }
  />,
  <Route
    key="edit"
    exact
    path="/edit/:projectId"
    element={
      <MainLayout isAdmin={isAdmin} langData={langData} currentLanguage={currentLanguage}>
        <EditPageWithMap method="put" langData={langData} />
      </MainLayout>
    }
  />,
  <Route
    key="create"
    exact
    path="/create"
    element={
      <MainLayout isAdmin={isAdmin} langData={langData} currentLanguage={currentLanguage}>
        <EditPageWithMap method="post" langData={langData} />
      </MainLayout>
    }
  />,
  <Route
    key="chart"
    exact
    path="/chart"
    element={
      <MainLayout isAdmin={isAdmin} langData={langData} currentLanguage={currentLanguage}>
        <ChartsPage langData={langData} />
      </MainLayout>
    }
  />,
  isAdmin ? (
    <Route
      key="dashboard"
      exact
      path="/dashboard"
      element={
        <MainLayout isAdmin={isAdmin} langData={langData} currentLanguage={currentLanguage}>
          <Dashboard langData={langData} />
        </MainLayout>
      }
    />
  ) : (
    <Route key="dashboard-fallback" path="/dashboard" element={<Navigate to="/" />} />
  )
];

export default routes;
